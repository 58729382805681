import React, {useState} from "react";
import {Link, useHistory} from "react-router-dom"
import {dispatch, translation} from "../../helpers";
import {login} from "../../helpers/apis";
import {setLoader, setLoggedInPerson, setSelectedChoiceR, setToken} from "../../redux/slices/globalSlice";

const Login = () => {
    const [formData, setFormData] = useState({
        username: "",
        password: "",
    });
    const [error, setError] = useState(null);
    const history = useHistory();

    const handleChange = (e) => {
        const {name, value} = e.target;
        setError(null);
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const {username, password} = formData;

        if (username || password) {
            try {
                dispatch(setLoader(true));
                const response = await login({login: username.trim(), password: password.trim()});

                if (response.data && response.data.token) {
                    dispatch(setToken(response.data.token));
                    dispatch(setLoggedInPerson(response.data.role));

                    if (response.data.role === "FuelSupervisor") {
                        dispatch(setSelectedChoiceR("Station Groups"))
                    }

                    history.push('/dashboard');
                    dispatch(setLoader(false));
                } else {
                    setError('Invalid credentials!');
                    dispatch(setLoader(false));
                }
            } catch (error) {
                dispatch(setLoader(false));
                setError('An error occurred while logging in.');
            }
        }
    };

    return (
        <div className="container">
            <i className="icon-car car-logo"/>
            <form className="form-login" onSubmit={handleSubmit}>
                <input type="text" name="username" placeholder={translation("Username")} autoComplete="off" maxLength={10}
                       onChange={handleChange}/>
                <input type="password" name="password" placeholder={translation("Password")} autoComplete="off" maxLength={10}
                       onChange={handleChange}/>
                <div className="button-container">
                    <button type="submit">{translation("Login")}</button>
                    <Link className="login-forgot-password" to="/forgot-password">
                        {translation("Forgot password")}
                    </Link>
                </div>
            </form>
            {error && <h3 className="error-message-login">{translation("Invalid credentials!")}</h3>}
        </div>
    );
};

export default Login;