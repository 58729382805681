import React from 'react';
import {translation} from "../../../../../helpers";

const DeleteDispenser = ({popupContent, handleSubmit, closeModal}) => {
    return (
        <div>
            <p>{translation("Are you sure you want to delete this?")} {popupContent.name || popupContent.serialNumber}</p>
            <form onSubmit={handleSubmit}>
                <div className="buttons-container">
                    <button className="custom-popup-submit-button" type="submit"
                            onClick={handleSubmit}>{translation("Save")}
                    </button>
                    <button className="custom-popup-cancel-button" type="button"
                            onClick={closeModal}>{translation("Cancel")}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default DeleteDispenser;