import React from 'react';
import {translation} from "../../../../../helpers";

const EditStationGroups = ({handleSubmit, popupContent, inputsData, handleChange, closeModal}) => {
    return (
        <div className="custom-modal">
            <div className="custom-modal-content">
                <h3 className="custom-modal-title">{translation("Edit Station Groups")}</h3>
                <form onSubmit={handleSubmit}>
                    <div className="input-container">
                        <label htmlFor="input1">{translation("Name")}</label>
                        <input autoComplete="off" type="text" name="name" value={inputsData.name}
                               placeholder={popupContent.name}
                               onChange={handleChange}/>
                    </div>

                    <div className="input-container">
                        <label htmlFor="input2">{translation("Address")}</label>
                        <input autoComplete="off" type="text" name="address" value={inputsData.address}
                               placeholder={popupContent.address} onChange={handleChange}/>
                    </div>

                    <div className="input-container">
                        <label htmlFor="input2">{translation("Phone Number")}</label>
                        <input autoComplete="off" type="text" name="phoneNumber" value={inputsData.phoneNumber}
                               placeholder={popupContent.phoneNumber} onChange={handleChange}/>
                    </div>


                    <div className="buttons-container">
                        <button className="custom-popup-submit-button" type="submit"
                                onClick={handleSubmit}>{translation("Save")}</button>
                        <button className="custom-popup-cancel-button" type="button"
                                onClick={closeModal}>{translation("Cancel")}</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditStationGroups;