import React, {useEffect, useRef} from 'react';
import {useSelector} from "react-redux";
import {showResultsList, translation} from "../../../../../helpers";
import {globalSelector} from "../../../../../redux/slices/globalSlice";

const AddNewDispenser = ({
 handleSubmit,
 inputsData,
 handleChange,
 selectedGroupDispensers,
 handleSelectStationGroup,
 stationGroupsData,
 selectedStationDispensers,
 stationsData,
 handleFuelChangeDispensers,
 selectedStationByID,
 selectedFuelsDispensers,
 closeModal,
 setSelectedStationDispensers,
 setSelectedStationGroupDispensers,
 results,
 setResults,
 showResults,
 setShowResults, query, setQuery
}) => {
    const {formFrontValidation} = useSelector(globalSelector);

    useEffect(() => {
        setSelectedStationGroupDispensers(stationsData?.list?.[0]?.stationGroup?.id)
    }, [closeModal]);

    const resultsContainerRef = useRef(null);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (
                resultsContainerRef.current &&
                !resultsContainerRef.current.contains(event.target)
            ) {
                setShowResults(false);
            }
        };

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    const handleInputChange = (event) => {
        const newQuery = event.target.value;
        setQuery(newQuery);
        showResultsList(newQuery, setResults);
    };

    const handleResultClick = (result) => {
        setSelectedStationDispensers(result.id);
        setQuery(result.name);
        setShowResults(false);
        showResultsList(result.name, setResults)
    };

    return (
        <div className="custom-modal">
            <div className="custom-modal-content">
                <h3 className="custom-modal-title">{translation("Add new dispenser")}</h3>
                <form onSubmit={handleSubmit}>
                    <div className="input-container">
                        <label htmlFor="input1">{translation("Serial Number")}</label>
                        <input autoComplete="off" type="text" name="serialNumber"
                               value={inputsData.serialNumber}
                               onChange={handleChange}/>
                        {
                            formFrontValidation?.serialNumber &&
                            <p className="error-input-field">{formFrontValidation?.serialNumber}</p>
                        }
                    </div>

                    <div className="input-container">
                        <label htmlFor="input2">{translation("Dispenser ID")}</label>
                        <input autoComplete="off" type="text" name="yandexDispenserId"
                               value={inputsData.yandexDispenserId}
                               onChange={handleChange}/>
                    </div>

                    <div className="input-container">
                        <label htmlFor="dropdown">{translation("Select Station Groups")}</label>
                        <select id="dropdown" value={selectedGroupDispensers} onChange={handleSelectStationGroup}>
                            {
                                stationGroupsData?.list?.map((option) => (
                                    <option key={option.id} value={option.id}>{option.name}</option>
                                ))}
                        </select>
                    </div>

                    {
                        selectedStationDispensers && stationsData?.list?.length ?
                            <div className="custom-select-container" ref={resultsContainerRef}>
                                <label htmlFor="dropdown">{translation("Select Station")}</label>
                                <input
                                    type="text"
                                    placeholder="Type to search..."
                                    value={query}
                                    className="station-input"
                                    onFocus={() => setShowResults(true)}
                                    onChange={handleInputChange}
                                />
                                {showResults && (
                                    <div className="results-container">
                                        {results.map((result) => (
                                            <div
                                                key={result.id}
                                                className="result"
                                                onClick={() => handleResultClick(result)}
                                            >
                                                {result.name}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div> : ""
                    }

                    {
                        selectedStationByID && query &&
                        <>
                            <div className="input-container">
                                <label htmlFor="dropdown">{translation("Select Fuel Types")}</label>
                                <select multiple onChange={handleFuelChangeDispensers}>
                                    {selectedStationByID?.fuelTypes?.map((fuel) => (
                                        <option
                                            key={fuel.id}
                                            value={fuel.id}>
                                            {fuel.name} ({fuel.countType})
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="selected-tags-container">
                                {selectedFuelsDispensers?.map((selectedFuel) => (
                                    <div className="selected-tag" key={selectedFuel}>
                                        {selectedStationByID?.fuelTypes.find((fuel) => fuel.id === selectedFuel)?.name} (
                                        {selectedStationByID?.fuelTypes.find((fuel) => fuel.id === selectedFuel)?.countType})
                                        <button
                                            className="close-button"
                                            onClick={() => handleFuelChangeDispensers(null, selectedFuel)}
                                        >
                                            x
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </>
                    }
                    <div className="buttons-container">
                        <button className="custom-popup-submit-button" type="submit"
                                onClick={handleSubmit}>{translation("Save")}</button>
                        <button className="custom-popup-cancel-button" type="button"
                                onClick={closeModal}>{translation("Cancel")}</button>
                    </div>
                </form>
            </div>
        </div>

    );
};

export default AddNewDispenser;