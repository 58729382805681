import React, {useEffect, useState} from 'react';
import moment from 'moment';
import {useSelector} from "react-redux";
import {getTransactions} from "../../../helpers/apis";
import {globalSelector, setLoader, setObjFilter, setTransactions} from "../../../redux/slices/globalSlice";
import {currentTimeInArmeniaEnd, dispatch, handleSort, translation} from "../../../helpers";

const TransactionsContent = ({dateStart, dateEnd}) => {
    const {token, transactionsData} = useSelector(globalSelector);
    const [expandedItemIds, setExpandedItemIds] = useState([]);

    const params = {
        pageIndex: '1',
        StartDate: dateStart,
        EndDate: currentTimeInArmeniaEnd(),
        field: "TransactionDate",
        orderBy: "desc"
    };

    useEffect(() => {
        dispatch(setLoader(true));
        dispatch(setObjFilter(params));
        getTransactions(token, params)
            .then(({data}) => dispatch(setTransactions(data)))
            .finally(() => dispatch(setLoader(false)))
    }, []);

    return (
        <div className="custom-list-container transactions">
            <table>
                <thead>
                <tr>
                    <th colSpan={2} onClick={() => handleSort('Id', dateStart, dateEnd)}>
                        <p>
                            <i className="icon-next-to-id id-icon"/>
                            <span>ID</span>
                        </p>
                    </th>
                    <th colSpan={2} title="Yandex ID"
                        onClick={() => handleSort('YandexTransactionId', dateStart, dateEnd)}>
                        <p>
                            <i className="icon-order id-icon"/>
                            {translation("Yandex ID")}
                        </p>
                    </th>
                    <th colSpan={2} title={translation("Transaction Type")}
                        onClick={() => handleSort('TransactionType', dateStart, dateEnd)}>
                        <p>
                            <i className="icon-order id-icon"/>
                            {translation("Transaction Type")}
                        </p>
                    </th>
                    <th colSpan={2} title={translation("Date of transaction")}
                        onClick={() => handleSort('TransactionDate', dateStart, dateEnd)}>
                        <p>
                            <i className="icon-order id-icon"/>
                            {translation("Date of transaction")}
                        </p>
                    </th>
                    <th colSpan={2} title={translation("Station")}
                        onClick={() => handleSort('StationName', dateStart, dateEnd)}>
                        <p>
                            <i className="icon-order id-icon"/>
                            {translation("Station")}
                        </p>
                    </th>
                    <th colSpan={2} title={translation("Tin")}
                        onClick={() => handleSort('tin', dateStart, dateEnd)}>
                        <p>
                            <i className="icon-order id-icon"/>
                            {translation("Tin")}
                        </p>
                    </th>
                    <th colSpan={2} title={translation("Station Group")}
                        onClick={() => handleSort('StationGroupName', dateStart, dateEnd)}>
                        <p>
                            <i className="icon-order id-icon"/>
                            {translation("Station Group")}
                        </p>
                    </th>
                    <th colSpan={2} title={translation("Fuel Type")}
                        onClick={() => handleSort('FuelTypeName', dateStart, dateEnd)}>
                        <p>
                            <i className="icon-order id-icon"/>
                            {translation("Fuel Type")}
                        </p>
                    </th>
                    <th colSpan={2} title={translation("Fuel volume")}
                        onClick={() => handleSort('FuelSize', dateStart, dateEnd)}>
                        <p>
                            <i className="icon-order id-icon"/>
                            {translation("Fuel volume")}
                        </p>
                    </th>
                    <th colSpan={2} title={translation("Transaction amount")}
                        onClick={() => handleSort('Amount', dateStart, dateEnd)}>
                        <p>
                            <i className="icon-order id-icon"/>
                            {translation("Transaction amount")}
                        </p>
                    </th>
                    <th colSpan={2} title={translation("Yandex status")}
                        onClick={() => handleSort('CurrentTransactionStatus', dateStart, dateEnd)}>
                        <p>
                            <i className="icon-order id-icon"/>
                            {translation("Yandex status")}
                        </p>
                    </th>
                    <th colSpan={2} title={translation("ECR status")}
                        onClick={() => handleSort('CurrentTaxStatus', dateStart, dateEnd)}>
                        <p>
                            <i className="icon-order id-icon"/>
                            {translation("ECR status")}
                        </p>
                    </th>
                    <th colSpan={2} title={translation("Receipt Id")}
                        onClick={() => handleSort('ReceiptId', dateStart, dateEnd)}>
                        <p>
                            <i className="icon-order id-icon"/>
                            {translation("Receipt Id")}
                        </p>
                    </th>
                </tr>
                </thead>

                <tbody>
                {transactionsData?.list?.map((product) => (
                    <tr key={product.id}>
                        <td colSpan={2}><p>{product.id}</p></td>
                        <td colSpan={2}>
                            <p className="yandexid-to-copy"
                               style={{wordBreak: expandedItemIds.includes(product.id) ? "break-all" : "unset"}}
                               onClick={() => {
                                   setExpandedItemIds((prevExpandedItemIds) => {
                                       if (prevExpandedItemIds.includes(product.id)) {
                                           return prevExpandedItemIds.filter((id) => id !== product.id);
                                       } else {
                                           return [...prevExpandedItemIds, product.id];
                                       }
                                   });
                                   navigator.clipboard.writeText(product.yandexTransactionId);
                               }}
                               title={product.yandexTransactionId}
                            >
                                {product.yandexTransactionId}
                            </p>
                        </td>
                        <td colSpan={2}><p>{product.transactionType}</p></td>
                        <td colSpan={2}><p>{moment(product.transactionDate).tz("Asia/Yerevan").format("YYYY-MM-DD HH:mm:ss")}</p></td>
                        <td colSpan={2}><p>{product.station.name}</p></td>
                        <td colSpan={2}><p>{product.station.tin}</p></td>
                        <td colSpan={2}><p>{product.station.stationGroup.name}</p></td>
                        <td colSpan={2}><p>{product.fuelType.name || product.fuelTypeName}</p></td>
                        <td colSpan={2}><p>{product.fuelSize} {product.fuelType.countType}</p></td>
                        <td colSpan={2}><p>{product.amount} AMD</p></td>
                        <td colSpan={2}><p>{product.currentTransactionStatus}</p></td>
                        <td colSpan={2}><p>{product.tax.currentTaxStatus}</p></td>
                        <td colSpan={2}><p>{product.tax.receiptId}</p></td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default TransactionsContent;
