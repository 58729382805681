import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import common_en from "./translations/en/common";
import common_am from "./translations/am/common";
import common_ru from "./translations/rus/common";


 const resources = {
    en: {translation: common_en},
    am: {translation: common_am},
    ru: {translation: common_ru}
};

 const languages = Object.entries(resources).map(([lang]) => lang);

export const getLngFromUrl = pathname => {
    for (let lang of languages) {
        if (pathname.startsWith(`/${lang}/`) || pathname === `/${lang}`) {
            return lang;
        }
    }
    return "am";
};

const lng = getLngFromUrl(window.location.pathname) || i18n.language;

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        lng,
        whitelist: ['en', 'am', 'ru'],
        fallbackLng: ['en'],
        detection: {
            caches: ['cookie']
        },
        interpolation: {
            escapeValue: false
        },
    });

export default i18n;