import React from 'react';
import {translation} from "../../../../../helpers";

const StationFuelPrices = ({closeModal, openPopup, fuelTypePrices, setFuelTypePrices, popupContent}) => {

    const handleInputChange = (fuelType, value) => {
        setFuelTypePrices((prevPrices) => ({
            ...prevPrices,
            [fuelType]: Number(value),
        }));
    };

    return (
        <div className="custom-modal-prices" style={{overflow: "auto", maxHeight: "85vh"}}>
            <h3 className="custom-modal-title">{translation("Change fuel prices in the Station")}</h3>
            <form onSubmit={() => openPopup("ConfirmFuelPricesStation")}>
                {popupContent?.fuelTypes?.map((item, i) => (
                        <div key={i} className="fuel-type-container">
                            <div className="text-container">
                                <label>{item.name}</label>
                            </div>
                            <div className="prices-input-container">
                                <input
                                    name={item.name}
                                    className="custom-input"
                                    value={fuelTypePrices[item.id] || ""}
                                    onChange={(e) => handleInputChange(item.id, e.target.value)}
                                />
                            </div>
                        </div>
                    ))}

                <div className="buttons-container">
                    <button className="custom-popup-submit-button" type="submit"
                            onClick={() => openPopup("ConfirmFuelPricesStation")}>{translation("Save")}
                    </button>
                    <button className="custom-popup-cancel-button" type="button"
                            onClick={closeModal}>{translation("Cancel")}
                    </button>
                </div>
            </form>
        </div>
    )
};

export default StationFuelPrices;