import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

function AuthRedirect({ token, children }) {
    const history = useHistory();

    useEffect(() => {
        history.push(token ? '/dashboard' : '/login');
    }, [history, token]);

    return children;
}

export default AuthRedirect;