import React, {useEffect} from 'react';
import {useSelector} from "react-redux";
import {dispatch} from "../../../helpers";
import FuelTypes from "./FuelTypes";
import FuelPrices from "./FuelPrices";
import UsersContent from "./UsersContent";
import StationsContent from "./StationsContent";
import DispensersContent from "./DispensersContent";
import TransactionsContent from "./TransactionsContent";
import {globalSelector, setLoader, setToStations} from "../../../redux/slices/globalSlice";

const Content = ({setSelectedChoice, openPopup, dateStart, dateEnd}) => {
    const {selectedChoice} = useSelector(globalSelector);

    useEffect(() => {
        const handleStorageChange = () => {
            setSelectedChoice(selectedChoice);
        };

        window.addEventListener('storage', handleStorageChange);
        dispatch(setLoader(false));

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    useEffect(() => {
        dispatch(setToStations({}));
    }, [selectedChoice]);

    switch (selectedChoice) {
        case "Fuel Types":
            return <FuelTypes openPopup={openPopup}/>;
        case "Station Groups":
            return <StationsContent openPopup={openPopup}/>;
        case "Fuel Prices":
            return <FuelPrices openPopup={openPopup}/>;
        case "Dispensers":
            return <DispensersContent openPopup={openPopup}/>;
        case "Users":
            return <UsersContent openPopup={openPopup}/>;
        case "Transactions":
            return <TransactionsContent dateStart={dateStart} dateEnd={dateEnd}/>;
        default:
            return null
    }
};

export default Content;