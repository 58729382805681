import React from 'react';
import {exportAsCSV, translation} from "../../../helpers";
import {useSelector} from "react-redux";
import {globalSelector} from "../../../redux/slices/globalSlice";

const Stations = ({searchValue, handleSearch, openPopup}) => {
    const {loggedInPerson, toStations} = useSelector(globalSelector);

    return (
        <div className="add-new">
            <div className="search-container">
                <form>
                    <input autoComplete="off"
                           type="text"
                           value={searchValue}
                           onChange={handleSearch}
                    />
                    <button onClick={(e) => handleSearch(e, searchValue)}>{translation("Search")}
                        <i className='icon-search add-new-icon'></i>
                    </button>
                </form>
            </div>
            {
                loggedInPerson === "Admin" ?
                    <>

                        <button onClick={exportAsCSV}>
                            <span className="add-new-text">{translation("Export")}</span>
                            <i className='icon-analytics add-new-icon'></i>
                        </button>

                        {
                            !toStations?.active ?
                                <button onClick={() => openPopup("AddNewStationGroup")}>
                                    <span
                                        className="add-new-text">{translation("Add new station groups")}</span>
                                    <i className='icon-add add-new-icon'></i>
                                </button> : ""
                        }


                        {
                            toStations.active && toStations.product.name ?
                                <button onClick={() => openPopup("AddNewStations")}>
                                    <span className="add-new-text">{translation("Add new station")}</span>
                                    <i className='icon-add add-new-icon'></i>
                                </button> : ""
                        }
                    </> :
                    ""
            }
        </div>
    );
};

export default Stations;