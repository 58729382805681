import React from "react";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.error(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className="error-page">
                    <h3>Something went wrong</h3>
                    <button onClick={() => window.location.href = '/login'}>
                        Back to Home
                    </button>
                </div>
            )
        }
        return this.props.children;
    }
}

export default ErrorBoundary
