import React from 'react';
import {useSelector} from "react-redux";
import {globalSelector} from "../../../redux/slices/globalSlice";
import {exportAsCSV, translation} from "../../../helpers";

const FuelPrices = ({searchValue, handleSearch, openPopup}) => {
    const {loggedInPerson, toStations} = useSelector(globalSelector);

    // console.log("Logged in person: ", loggedInPerson);
    // console.log("To stations: ", JSON.stringify(toStations, null, 2));

    return (
        <div className="add-new">
            <div className="search-container">
                <form>
                    <input autoComplete="off"
                           type="text"
                           value={searchValue}
                           onChange={handleSearch}
                    />
                    <button onClick={(e) => handleSearch(e, searchValue)}>{translation("Search")}
                        <i className='icon-search add-new-icon'></i>
                    </button>
                </form>
            </div>
            {
                loggedInPerson === "Admin" && toStations.active && toStations.product.name ?
                    <>

                        <button onClick={exportAsCSV}>
                            <span className="add-new-text">{translation("Export")}</span>
                            <i className='icon-analytics add-new-icon'></i>
                        </button>
                    </>
                    : ""
            }

            {
                toStations.active && toStations.product.name ?
                    <button onClick={() => openPopup("EditAllPrices")}>
                        <span className="add-new-text">{translation("Change all fuel prices in the Station")}</span>
                        <i className='icon-add add-new-icon'></i>
                    </button> : ""
            }
            {
                loggedInPerson === "Admin" && toStations.active && toStations.product.name ?
                    <button onClick={() => openPopup("AddAllDiscounts")}>
                        <span className="add-new-text">{translation("add_discounts_on_all_fuel_in_the_station")}</span>
                        <i className='icon-add add-new-icon'></i>
                    </button> : ""
            }
        </div>
    );
};

export default FuelPrices;