import React, {useState} from 'react';
import {translation} from "../../helpers";
import {Link} from "react-router-dom";
import {forgotPassword} from "../../helpers/apis";

const ForgotPassword = () => {
    const [username, setUserName] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        await forgotPassword({login: username})
    };

    return (
        <div className="container">
            <i className="icon-car car-logo" />
            <form className="form-login" onSubmit={handleSubmit}>
                <input
                    type="text"
                    name="username"
                    value={username}
                    placeholder={translation("Username")}
                    autoComplete="off"
                    maxLength={10}
                    onChange={(e)=> setUserName(e.target.value)}
                />
                <div className="button-container">
                    <button type="submit">{translation("Reset Password")}</button>
                    <Link className="login-forgot-password" to="/login">
                        {translation("Login")}
                    </Link>
                </div>
            </form>
        </div>
    );
};

export default ForgotPassword;