import React from 'react';
import {useSelector} from "react-redux";
import {translation} from "../../../../../helpers";
import {globalSelector} from "../../../../../redux/slices/globalSlice";

const AddNewFuelType = ({handleSubmit, inputsData, handleDropdownChange, selectedOption, handleChange, closeModal}) => {
    const {formFrontValidation} = useSelector(globalSelector);

    return (
        <div className="custom-modal">
            <div className="custom-modal-content">
                <h3 className="custom-modal-title">{translation("Add new fuel")}</h3>
                <form onSubmit={handleSubmit}>
                    <div className="input-container">
                        <label htmlFor="input1">{translation("Fuel Name")}</label>
                        <input autoComplete="off" type="text" name="name" value={inputsData.name}
                               onChange={handleChange}/>
                        {
                            formFrontValidation?.name &&
                            <p className="error-input-field">{formFrontValidation?.name}</p>
                        }
                    </div>

                    <div className="input-container">
                        <label htmlFor="input1">{translation("Adg code")}</label>
                        <input autoComplete="off" type="text" name="adgCode" value={inputsData.adgCode}
                               onChange={handleChange}/>

                        {
                            formFrontValidation?.adgCode &&
                            <p className="error-input-field">{formFrontValidation?.adgCode}</p>
                        }
                    </div>

                    <div className="input-container">
                        <label htmlFor="input1">{translation("Department Id")}</label>
                        <input autoComplete="off" type="text" name="departmentId" value={inputsData.departmentId}
                               onChange={handleChange}/>

                        {
                            formFrontValidation?.departmentId &&
                            <p className="error-input-field">{formFrontValidation?.departmentId}</p>
                        }
                    </div>

                    <div className="input-container">
                        <label htmlFor="input2">{translation("Yandex ID")}</label>
                        <input autoComplete="off" type="text" name="yandexFuelTypeId"
                               value={inputsData.yandexFuelTypeId}
                               onChange={handleChange}/>
                        {
                            formFrontValidation?.yandexFuelTypeId &&
                            <p className="error-input-field">{formFrontValidation?.yandexFuelTypeId}</p>
                        }
                    </div>

                    <div className="input-container">
                        <label htmlFor="dropdown">{translation("Select unit of measurement")}</label>
                        <select id="dropdown" value={selectedOption} onChange={handleDropdownChange}>
                            <option value="Kg">Kg</option>
                            <option value="L">Ltr</option>
                        </select>
                    </div>

                    <div className="buttons-container">
                        <button className="custom-popup-submit-button" type="submit"
                                onClick={handleSubmit}>{translation("Save")}</button>
                        <button className="custom-popup-cancel-button" type="button"
                                onClick={closeModal}>{translation("Cancel")}</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddNewFuelType;