import React, {useEffect, useState} from 'react';
import "../../../assets/styles/CustomPopup.css"
import {dispatch, handleSubmit, showResultsList} from "../../../helpers";
import {useSelector} from "react-redux";
import {
    globalSelector,
    setFrontValidation,
    setFuelTypes,
    setLoader,
    setStationGroups,
    setStations
} from "../../../redux/slices/globalSlice";
import {getFuelTypesForSelect, getStationGroups, getStations} from "../../../helpers/apis";
import AddNewFuelType from "./custom-popup/fuelTypes/AddNewFuelType";
import DeleteFuelType from "./custom-popup/fuelTypes/DeleteFuelType";
import DeleteStationGroups from "./custom-popup/stationGroups/DeleteStationGroups";
import DeleteStations from "./custom-popup/stations/DeleteStations";
import EditFuelTypes from "./custom-popup/fuelTypes/EditFuelTypes";
import EditStationGroups from "./custom-popup/stationGroups/EditStationGroups";
import EditStations from "./custom-popup/stations/EditStations";
import AddNewDispenser from "./custom-popup/dispensers/AddNewDispenser";
import EditDispenser from "./custom-popup/dispensers/EditDispenser";
import DeleteDispenser from "./custom-popup/dispensers/DeleteDispenser";
import DeleteUsers from "./custom-popup/users/DeleteUsers";
import EditUser from "./custom-popup/users/EditUser";
import AddNewUser from "./custom-popup/users/AddNewUser";
import AddNewStationGroup from "./custom-popup/stationGroups/AddNewStationGroup";
import AddNewStations from "./custom-popup/stations/AddNewStations";
import FuelPrices from "./custom-popup/fuelPrices/FuelPrices";
import FuelDiscounts from './custom-popup/fuelPrices/FuelDiscounts';
import ConfirmFuelPrices from "./custom-popup/fuelPrices/ConfirmFuelPrices";
import StationFuelPrices from "./custom-popup/fuelPrices/StationFuelPrices";
import ConfirmFuelPricesStation from "./custom-popup/fuelPrices/ConfirmFuelPricesStation";

const CustomPopup = ({popupType, closeModal, popupContent, openPopup}) => {
    const {selectedChoice, token, fuelTypesData, toStations, stationGroupsData, stationsData} = useSelector(globalSelector);
    const [showResults, setShowResults] = useState(false);
    const [searchStation, setSearchStation] = useState("");
    const [role, setRole] = useState(popupContent?.role || 'Admin');
    const [selectedOption, setSelectedOption] = useState(popupContent?.countType || "Kg");
    const defaultStationId = stationsData?.list?.[0]?.id || null;
    const defaultStationGroupId = stationGroupsData?.list?.[0]?.id || null;
    const [selectedStationDispensers, setSelectedStationDispensers] = useState(popupContent?.station?.id || defaultStationId);
    const [selectedGroupDispensers, setSelectedStationGroupDispensers] = useState(
        popupContent?.station?.stationGroup?.id ||
        defaultStationGroupId
    );
    const [selectedStationUsers, setSelectedStationUsers] = useState(defaultStationId);
    const [selectedGroupUsers, setSelectedStationGroupUsers] = useState(popupContent?.stations?.[0]?.stationGroup?.id || defaultStationGroupId);
    const selectedStationByID = stationsData?.list?.find((station) => Number(station.id) === Number(selectedStationDispensers));
    const [query, setQuery] = useState("");
    const [checkedIds, setCheckedIds] = useState(
        selectedChoice === "Dispensers" ? [] :
            popupContent?.stations?.map((item) => item.id) || []
    );
    const [results, setResults] = useState(stationsData?.list);
    const [userChecked, setUserChecked] = useState(popupContent?.stations?.map(userStation => userStation.id) || []);
    const [selectedFuelsDispensers, setSelectedFuelsDispensers] = useState(popupContent?.fuelTypes?.map(item => item.id) || []);
    const [selectedFuelsStations, setSelectedFuelsStations] = useState(popupContent?.fuelTypes?.map(item => item.id) || []);
    const [inputsData, setInputsData] = useState({
        name: popupContent?.name || "",
        yandexFuelTypeId: popupContent?.yandexFuelTypeId || "",
        phoneNumber: popupContent?.phoneNumber || "",
        address: popupContent?.address || "",
        lat: popupContent?.lat || "",
        long: popupContent?.long || "",
        serialNumber: popupContent?.serialNumber || "",
        tin: popupContent?.tin || "",
        fuelTypes: [],
        yandexDispenserId: popupContent?.yandexDispenserId || "",
        yandexStationId: popupContent?.yandexStationId,
        stationGroupId: toStations?.product?.id || "",
        firstName: popupContent?.firstName || "",
        lastName: popupContent?.lastName || "",
        email: popupContent?.email || "",
        adgCode: popupContent?.adgCode || "",
        departmentId: popupContent?.departmentId || "",
        id: popupContent?.id || ""
    });

    const [fuelTypePrices, setFuelTypePrices] = useState(() => {
        const initialPrices = {};
        popupContent?.fuelTypes?.forEach(item => {
            initialPrices[item?.id] = item?.price;
        });
        return initialPrices;
    });

    const [fuelTypeDiscounts, setFuelTypeDiscounts] = useState(() => {
        const initialDiscounts = {};
        popupContent?.fuelTypes?.forEach(item => {
            initialDiscounts[item?.id] = item?.discount;
        });
        return initialDiscounts;
    });

    useEffect(() => {
        if (selectedChoice !== "Fuel Types" && selectedChoice !== "Fuel Prices") {
            getFuelTypesForSelect(token, fuelTypesData?.count)
                .then(({data}) => dispatch(setFuelTypes(data)))
                .finally(() => dispatch(setLoader(false)));
        }
        if (!stationGroupsData?.list?.length) {
            getStationGroups(token)
                .then(({data}) => dispatch(setStationGroups(data)))
                .finally(() => dispatch(setLoader(false)));
        }
    }, []);

    const handleCheckboxChange = (event, id) => {
        if (event.target.checked) {
            setCheckedIds([...checkedIds, id]);
        } else {
            setCheckedIds(checkedIds.filter(checkedId => checkedId !== id));
        }
    };

    const handleFuelChangeDispensers = (event, value) => {
        if (event) {
            const selectedValue = parseInt(event.target.value, 10);

            if (selectedFuelsDispensers.includes(selectedValue)) {
                setSelectedFuelsDispensers(selectedFuelsDispensers.filter((fuel) => fuel !== selectedValue));
            } else {
                setSelectedFuelsDispensers([...selectedFuelsDispensers, selectedValue]);
            }
        } else if (value) {
            setSelectedFuelsDispensers((prevSelectedFuels) => {
                if (prevSelectedFuels.includes(value)) {
                    return prevSelectedFuels.filter((fuel) => fuel !== value);
                } else {
                    return [...prevSelectedFuels, value];
                }
            });
        }
    };

    const handleFuelChangeStations = (event, value) => {
        if (event) {
            const selectedValue = parseInt(event.target.value, 10);

            if (selectedFuelsStations.includes(selectedValue)) {
                setSelectedFuelsStations(selectedFuelsStations.filter((fuel) => fuel !== selectedValue));
            } else {
                setSelectedFuelsStations([...selectedFuelsStations, selectedValue]);
            }
            setInputsData((prevInputsData) => ({
                ...prevInputsData,
                fuelTypes: [...selectedFuelsStations, selectedValue],
            }));
        } else if (value) {
            setSelectedFuelsStations((prevSelectedFuels) => {
                if (prevSelectedFuels.includes(value)) {
                    return prevSelectedFuels.filter((fuel) => fuel !== value);
                } else {
                    return [...prevSelectedFuels, value];
                }

            });
            setInputsData((prevInputsData) => ({
                ...prevInputsData,
                fuelTypes: [...selectedFuelsStations, value],
            }));
        }
    };

    const handleDropdownChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleRoleChange = (event) => {
        setRole(event.target.value);
    };

    const handleSelectStationGroup = (event) => {
        const selectedGroup = event.target.value;
        setCheckedIds([]);
        setUserChecked([]);

        getStations({StationGroupId: Number(selectedGroup)}, token,{pageSize: "300"})
            .then(({data}) => {
                dispatch(setStations(data));
                if (popupType === "EditDispenser") {
                    setSelectedStationGroupDispensers(Number(selectedGroup));
                    setSelectedStationDispensers(data?.list[0]?.id);
                    setQuery("");
                    showResultsList("", setResults)
                } else {
                    setSelectedStationGroupUsers(Number(selectedGroup));
                    setSelectedStationUsers(data?.list[0]?.id);
                }
            })
            .finally(() => dispatch(setLoader(false)));
    };

    const handleSelectStation = (event) => {
        setCheckedIds([]);
        setUserChecked([]);

        if (selectedChoice === "Dispensers") {
            setSelectedStationDispensers(event.target.value)

        } else {
            setSelectedStationUsers(event.target.value)
        }
    };

    const handleChange = (e) => {
        dispatch(setFrontValidation({}));
        const {name, value} = e.target;
        setInputsData({
            ...inputsData,
            [name]: value,
        });
    };

    const filteredStations = stationsData?.list?.filter(option =>
        option.name.toLowerCase().includes(searchStation.toLowerCase())
    );

    const handleFormSubmit = (event) => {
        event.preventDefault();

        handleSubmit(
            popupType,
            popupContent,
            inputsData,
            selectedOption,
            selectedStationUsers,
            selectedStationDispensers,
            selectedGroupDispensers,
            selectedGroupUsers,
            setSelectedStationDispensers,
            setSelectedStationGroupDispensers,
            setSelectedStationGroupUsers,
            selectedFuelsDispensers,
            selectedFuelsStations,
            checkedIds,
            token,
            role,
            closeModal,
            toStations,
            fuelTypePrices,
            fuelTypeDiscounts
        );
    };

    let popupsContent;
    switch (popupType) {
        case 'AddNewFuelType':
            popupsContent = (
                <AddNewFuelType
                    inputsData={inputsData}
                    closeModal={closeModal}
                    handleSubmit={handleFormSubmit}
                    handleChange={handleChange}
                    selectedOption={selectedOption}
                    handleDropdownChange={handleDropdownChange}
                />
            );
            break;

        case 'EditFuelType':
            popupsContent = (
                <EditFuelTypes
                    inputsData={inputsData}
                    closeModal={closeModal}
                    handleSubmit={handleFormSubmit}
                    popupContent={popupContent}
                    handleChange={handleChange}
                    selectedOption={selectedOption}
                    handleDropdownChange={handleDropdownChange}
                />
            );
            break;

        case 'DeleteFuelType':
            popupsContent = (
                <DeleteFuelType
                    closeModal={closeModal}
                    handleSubmit={handleFormSubmit}
                    popupContent={popupContent}
                />
            );
            break;

        case 'AddNewStationGroup':
            popupsContent = (
                <AddNewStationGroup
                    closeModal={closeModal}
                    inputsData={inputsData}
                    handleSubmit={handleFormSubmit}
                    handleChange={handleChange}
                />
            );
            break;

        case "EditStationGroups":
            popupsContent = (
                <EditStationGroups
                    inputsData={inputsData}
                    closeModal={closeModal}
                    handleSubmit={handleFormSubmit}
                    popupContent={popupContent}
                    handleChange={handleChange}
                />
            );
            break;

        case 'DeleteStationGroups':
            popupsContent = (
                <DeleteStationGroups
                    closeModal={closeModal}
                    handleSubmit={handleFormSubmit}
                    popupContent={popupContent}
                />
            );
            break;

        case 'AddNewStations':
            popupsContent = (
                <AddNewStations
                    closeModal={closeModal}
                    inputsData={inputsData}
                    handleSubmit={handleFormSubmit}
                    handleChange={handleChange}
                    fuelTypesData={fuelTypesData}
                    selectedFuelsStations={selectedFuelsStations}
                    handleFuelChangeStations={handleFuelChangeStations}
                />
            );
            break;

        case "EditStations":
            popupsContent = (
                <EditStations
                    inputsData={inputsData}
                    closeModal={closeModal}
                    handleSubmit={handleFormSubmit}
                    handleChange={handleChange}
                    popupContent={popupContent}
                    fuelTypesData={fuelTypesData}
                    handleFuelChangeStations={handleFuelChangeStations}
                    selectedFuelsStations={selectedFuelsStations}
                />
            );
            break;

        case 'DeleteStations':
            popupsContent = (
                <DeleteStations
                    closeModal={closeModal}
                    handleSubmit={handleFormSubmit}
                    popupContent={popupContent}
                />
            );
            break;

        case "EditAllPrices" :
            popupsContent = (
                <FuelPrices
                    openPopup={openPopup}
                    closeModal={closeModal}
                    inputsData={inputsData}
                    fuelTypePrices={fuelTypePrices}
                    setFuelTypePrices={setFuelTypePrices}
                />
            );
            break;

        case "AddAllDiscounts" :
            popupsContent = (
                <FuelDiscounts
                    openPopup={openPopup}
                    closeModal={closeModal}
                    inputsData={inputsData}
                    fuelTypeDiscounts={fuelTypeDiscounts}
                    setFuelTypeDiscounts={setFuelTypeDiscounts}
                />
            );
            break;

        case "EditStationPrices" :
            popupsContent = (
                <StationFuelPrices
                    openPopup={openPopup}
                    closeModal={closeModal}
                    inputsData={inputsData}
                    popupContent={popupContent}
                    fuelTypePrices={fuelTypePrices}
                    setFuelTypePrices={setFuelTypePrices}
                />
            );
            break;

        case "ConfirmFuelPrices" :
            popupsContent = (
                <ConfirmFuelPrices
                    popupType={popupType}
                    closeModal={closeModal}
                    handleSubmit={handleFormSubmit}
                />
            );
            break;

        case "ConfirmFuelPricesStation" :
            popupsContent = (
                <ConfirmFuelPricesStation
                    popupType={popupType}
                    closeModal={closeModal}
                    handleSubmit={handleFormSubmit}
                />
            );
            break;

        case "ConfirmFuelDiscounts" :
            popupsContent = (
                <ConfirmFuelPrices
                    popupType={popupType}
                    closeModal={closeModal}
                    handleSubmit={handleFormSubmit}
                />
            );
            break;

        case "AddNewDispenser":
            popupsContent = (
                <AddNewDispenser
                    closeModal={closeModal}
                    inputsData={inputsData}
                    handleSubmit={handleFormSubmit}
                    handleChange={handleChange}
                    stationsData={stationsData}
                    results={results}
                    setResults={setResults}
                    showResults={showResults}
                    setShowResults={setShowResults}
                    query={query}
                    setQuery={setQuery}
                    setSelectedStationDispensers={setSelectedStationDispensers}
                    selectedStationDispensers={selectedStationDispensers}
                    selectedGroupDispensers={selectedGroupDispensers}
                    stationGroupsData={stationGroupsData}
                    selectedStationByID={selectedStationByID}
                    setSelectedStationGroupDispensers={setSelectedStationGroupDispensers}
                    handleSelectStation={handleSelectStation}
                    selectedFuelsDispensers={selectedFuelsDispensers}
                    handleSelectStationGroup={handleSelectStationGroup}
                    handleFuelChangeDispensers={handleFuelChangeDispensers}
                />
            );
            break;

        case "EditDispenser":
            popupsContent = (
                <EditDispenser
                    closeModal={closeModal}
                    inputsData={inputsData}
                    popupContent={popupContent}
                    handleChange={handleChange}
                    stationsData={stationsData}
                    handleSubmit={handleFormSubmit}
                    selectedGroupDispensers={selectedGroupDispensers}
                    selectedStationDispensers={selectedStationDispensers}
                    setSelectedStationGroupDispensers={setSelectedStationGroupDispensers}
                    setSelectedStationDispensers={setSelectedStationDispensers}
                    stationGroupsData={stationGroupsData}
                    handleSelectStation={handleSelectStation}
                    selectedStationByID={selectedStationByID}
                    selectedFuelsDispensers={selectedFuelsDispensers}
                    handleSelectStationGroup={handleSelectStationGroup}
                    handleFuelChangeDispensers={handleFuelChangeDispensers}
                />
            );
            break;

        case "DeleteDispenser":
            popupsContent = (
                <DeleteDispenser
                    closeModal={closeModal}
                    handleSubmit={handleFormSubmit}
                    popupContent={popupContent}
                />
            );
            break;

        case "AddNewUser":
            popupsContent = (
                <AddNewUser
                    role={role}
                    closeModal={closeModal}
                    inputsData={inputsData}
                    handleSubmit={handleFormSubmit}
                    handleChange={handleChange}
                    stationsData={stationsData}
                    searchStation={searchStation}
                    setSearchStation={setSearchStation}
                    filteredStations={filteredStations}
                    handleRoleChange={handleRoleChange}
                    stationGroupsData={stationGroupsData}
                    selectedGroupUsers={selectedGroupUsers}
                    handleCheckboxChange={handleCheckboxChange}
                    handleSelectStationGroup={handleSelectStationGroup}
                />
            );
            break;

        case "EditUser":
            popupsContent = (
                <EditUser
                    role={role}
                    popupContent={popupContent}
                    closeModal={closeModal}
                    inputsData={inputsData}
                    userChecked={userChecked}
                    setUserChecked={setUserChecked}
                    handleSubmit={handleFormSubmit}
                    handleChange={handleChange}
                    searchStation={searchStation}
                    setCheckedIds={setCheckedIds}
                    setSearchStation={setSearchStation}
                    handleRoleChange={handleRoleChange}
                    filteredStations={filteredStations}
                    stationGroupsData={stationGroupsData}
                    selectedGroupUsers={selectedGroupUsers}
                    handleCheckboxChange={handleCheckboxChange}
                    handleSelectStationGroup={handleSelectStationGroup}
                />
            );
            break;

        case "DeleteUser":
            popupsContent = (
                <DeleteUsers
                    closeModal={closeModal}
                    handleSubmit={handleFormSubmit}
                    popupContent={popupContent}
                />
            );
            break;

        default:
            popupsContent = null;
    }

    return (
        <div className="popup-overlay">
            <div className="custom-modal">
                <div className="custom-modal-content">
                    {popupsContent}
                </div>
            </div>
        </div>
    );
};

export default CustomPopup;