import React from 'react';
import {useSelector} from "react-redux";
import DatesCalendar from "../calendar/Calendar";
import {globalSelector} from "../../../redux/slices/globalSlice";
import {exportAsCsvSummary, exportAsCsvTransactions, translation} from "../../../helpers";

const Transactions = ({
  searchValue,
  dateEnd,
  dateStart,
  setDateEnd,
  handleSearch,
  setDateStart,
  calendarEndRef,
  calendarStartRef,
  setShowCalenderEnd,
  handleSelectChange,
  setShowCalenderStart,
  handleChangeFuelTypeId,
}) => {
    const {fuelTypesData} = useSelector(globalSelector);
    const { loggedInPerson } = useSelector(globalSelector);

    return (
        <div className="add-new" style={{height: "90px"}}>
            <div className="search-container">
                <form>
                    <div className="input-container">
                        <label>{translation("Search")}</label>
                        <input autoComplete="off" type="text" value={searchValue} onChange={handleSearch}/>
                    </div>
                    <div className="input-container">
                        <label htmlFor="dropdown1">{translation("Select Transaction Type")}</label>
                        <select className="dropdown" id="dropdown1"
                                onChange={handleSelectChange}>
                            <option value="All">All</option>
                            <option value="Sale">Sale</option>
                            <option value="Refund">Refund</option>
                            {/*<option value="PartialRefund">Partial Refund</option>*/}
                        </select>
                    </div>
                    <div className="input-container">
                        <label htmlFor="dropdown2">{translation("Select fuel type")}</label>
                        <select
                            className="dropdown"
                            id="dropdown2"
                            onChange={handleChangeFuelTypeId}>

                            <option value="All" key="All">
                                All
                            </option>

                            {
                                fuelTypesData?.list?.map((fuel) => {
                                    return (
                                        <option value={fuel.name} key={fuel.id}>
                                            {fuel.name}
                                        </option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className="input-container">
                        <label htmlFor="input2">{translation("Start")}</label>
                            <div ref={calendarStartRef}>
                                <DatesCalendar
                                    date={dateStart}
                                    setDate={setDateStart}
                                    onClose={() => setShowCalenderStart(false)}
                                    className="calendarStart"
                                />
                            </div>

                    </div>
                    <div className="input-container">
                        <label htmlFor="input3">{translation("End")}</label>
                            <div ref={calendarEndRef} onClick={() => setShowCalenderEnd(true)}>
                                <DatesCalendar
                                    date={dateEnd}
                                    setDate={setDateEnd}
                                    onClose={() => setShowCalenderEnd(false)}
                                    className="calendarEnd"
                                />
                            </div>
                    </div>

                    <button onClick={(e) => handleSearch(e, searchValue)}>{translation("Search")}
                        <i className='icon-search add-new-icon'></i>
                    </button>
                </form>
            </div>
            <button className="add-new-button" onClick={exportAsCsvTransactions}>
                <span className="add-new-text">{translation("Report")}</span>
            </button>
            {loggedInPerson === "FuelSupervisor" &&
                <button className="add-new-button" onClick={exportAsCsvSummary}>
                    <span className="add-new-text">{translation("stations_summary_report")}</span>
                </button>
            }
        </div>
    );
};

export default Transactions;