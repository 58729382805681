import React from 'react';
import {exportAsCSV, translation} from "../../../helpers";

const Dispensers = ({searchValue, handleSearch, openPopup}) => {
    return (
        <div className="add-new">

            <div className="search-container">
                <form>
                    <input autoComplete="off" type="text" value={searchValue} onChange={handleSearch}/>
                    <button onClick={(e) => handleSearch(e, searchValue)}>{translation("Search")}
                        <i className='icon-search add-new-icon'></i></button>
                </form>
            </div>
            <button onClick={exportAsCSV}>
                <span className="add-new-text">{translation("Export")}</span>
                <i className='icon-analytics add-new-icon'></i>
            </button>

            <button onClick={() => openPopup("AddNewDispenser")}>
                <span className="add-new-text">{translation("Add new dispenser")}</span>
                <i className='icon-add add-new-icon'></i>
            </button>
        </div>
    );
};

export default Dispensers;