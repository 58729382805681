import {AxiosInstance} from "./AxiosInstance";

const API_URL = process.env.REACT_APP_API_URL;
export const LOGIN = `${API_URL}Users/login`;
export const USERS = `${API_URL}Users`;
export const USERS_REGISTRATION = `${API_URL}Users/registration`;
export const EXPORT_CSV = `${API_URL}files/download-csv`;
export const EXPORT_CSV_TRANSACTIONS = `${API_URL}files/download-csv-tr`;
export const EXPORT_CSV_SUMMARY = `${API_URL}files/download-summary`;
export const EXPORT_CSV_STATIONS = `${API_URL}files/download-csv-st`;
export const FORGOT_PASSWORD = `${API_URL}Users/forgot-password`;
export const FUEL_TYPES = `${API_URL}FuelTypes`;
export const ADD_FUEL_TYPES = `${API_URL}FuelTypes`;
export const EDIT_FUEL_TYPES = `${API_URL}FuelTypes`;
export const DELETE_FUEL_TYPES = `${API_URL}FuelTypes`;

export const STATION_GROUPS = `${API_URL}StationGroups`;
export const ADD_STATION_GROUPS = `${API_URL}StationGroups`;
export const EDIT_STATION_GROUPS = `${API_URL}StationGroups`;
export const DELETE_STATION_GROUPS = `${API_URL}StationGroups`;
export const STATIONS = `${API_URL}Stations`;

export const DISPENSERS = `${API_URL}Dispensers`;

export const TRANSACTIONS = `${API_URL}Transactions`;
export const PRICES = `${API_URL}Prices`;
export const DISCOUNTS = `${API_URL}Discounts`;

export const forgotPassword = (body, headers) => {
    return AxiosInstance('POST', FORGOT_PASSWORD, {body, headers})
};

export const login = (body, headers) => {
    return AxiosInstance('POST', LOGIN, {body, headers})
};

export const getFuelTypes = (token, queries) => {
    let queryString = "?";
    if (queries?.pageIndex) queryString += `&PageIndex=${queries.pageIndex}`;
    if (queries?.pageSize) queryString += `&pageSize=${queries.pageSize}`;
    if (queries?.field) queryString += `&OrderBy=${queries.field}`;
    if (queries?.orderBy) queryString += `&OrderDir=${queries.orderBy}`;

    return AxiosInstance('GET', FUEL_TYPES + queryString, {}, token)
};

export const getFuelTypesForSelect = (token, pageSize) => {
    let queryString = "?";
    queryString += `PageSize=${pageSize || 1}`;

    return AxiosInstance('GET', FUEL_TYPES + queryString, {}, token)
};

export const addNewFuelTypes = (body, token) => {
    return AxiosInstance('POST', ADD_FUEL_TYPES, {body}, token);
};

export const editFuelTypes = (body, token) => {
    return AxiosInstance('PUT', EDIT_FUEL_TYPES, {body}, token);
};

export const deleteFuelTypes = (token, id) => {
    return AxiosInstance('DELETE', `${DELETE_FUEL_TYPES}/${id}`, {}, token);
};

export const getStationGroups = (token, queries) => {
    let queryString = "?";
    if (queries?.pageIndex) queryString += `&PageIndex=${queries.pageIndex}`;
    if (queries?.field) queryString += `&OrderBy=${queries.field}`;
    if (queries?.pageSize) queryString += `&pageSize=${queries.pageSize}`;
    if (queries?.orderBy) queryString += `&OrderDir=${queries.orderBy}`;
    if (queries?.searchText) queryString += `&SearchText=${queries.searchText}`;

    return AxiosInstance('GET', STATION_GROUPS + queryString, {}, token)
};

export const addNewStationGroups = (body, token) => {
    return AxiosInstance('POST', ADD_STATION_GROUPS, {body}, token);
};

export const editStationGroups = (body, token) => {
    return AxiosInstance('PUT', EDIT_STATION_GROUPS, {body}, token);
};

export const deleteStationGroups = (token, id) => {
    return AxiosInstance('DELETE', `${DELETE_STATION_GROUPS}/${id}`, {}, token);
};

export const getStations = (body, token, queries) => {
    let queryString = "?";
    if (queries?.pageIndex) queryString += `&PageIndex=${queries.pageIndex}`;
    if (queries?.field) queryString += `&OrderBy=${queries.field}`;
    if (queries?.pageSize) queryString += `&pageSize=${queries.pageSize}`;
    if (queries?.orderBy) queryString += `&OrderDir=${queries.orderBy}`;
    if (queries?.searchText) queryString += `&SearchText=${queries.searchText}`;

    return AxiosInstance('GET', `${STATIONS + queryString}&StationGroupId=${body.StationGroupId}`, {body}, token)
};

export const addNewStation = (body, token) => {
    return AxiosInstance('POST', STATIONS, {body}, token);
};

export const editStations = (body, token) => {
    return AxiosInstance('PUT', STATIONS, {body}, token);
};

export const deleteStationsApi = (token, id) => {
    return AxiosInstance('DELETE', `${STATIONS}/${id}`, {}, token);
};


export const getDispensers = (token, queries) => {
    let queryString = "?";
    if (queries?.pageIndex) queryString += `&PageIndex=${queries.pageIndex}`;
    if (queries?.field) queryString += `&OrderBy=${queries.field}`;
    if (queries?.orderBy) queryString += `&OrderDir=${queries.orderBy}`;
    if (queries?.searchText) queryString += `&SearchText=${queries.searchText}`;

    return AxiosInstance('GET', DISPENSERS + queryString, {}, token)
};

export const addNewDispensers = (body, token) => {
    return AxiosInstance('POST', DISPENSERS, {body}, token);
};

export const editDispensers = (body, token) => {
    return AxiosInstance('PUT', DISPENSERS, {body}, token);
};

export const deleteDispenserApi = (token, id) => {
    return AxiosInstance('DELETE', `${DISPENSERS}`, {}, token);
};

export const getUsers = (body, token, queries) => {
    let queryString = "?";
    if (queries?.pageIndex) queryString += `&PageIndex=${queries.pageIndex}`;
    if (queries?.field) queryString += `&OrderBy=${queries.field}`;
    if (queries?.orderBy) queryString += `&OrderDir=${queries.orderBy}`;
    if (queries?.searchText) queryString += `&SearchText=${queries.searchText}`;

    return AxiosInstance('GET', USERS + queryString, {}, token)
};

export const addNewUser = (body, token) => {
    return AxiosInstance('POST', USERS_REGISTRATION, {body}, token);
};

export const editUsers = (body, token) => {
    return AxiosInstance("PUT", USERS, {body}, token)
};

export const deleteUser = (id, token) => {
  return AxiosInstance("DELETE", `${USERS}/${id}`, {}, token)
};

export const getTransactions = (token, queries) => {
    let queryString = "?";
    if (queries?.pageIndex) queryString += `&PageIndex=${queries.pageIndex}`;
    if (queries?.field) queryString += `&OrderBy=${queries.field}`;
    if (queries?.FuelTypeId) queryString += `&FuelTypeId=${queries.FuelTypeId}`;
    if (queries?.FuelTypeName) queryString += `&FuelTypeName=${queries.FuelTypeName}`;
    if (queries?.orderBy) queryString += `&OrderDir=${queries.orderBy}`;
    if (queries?.TransactionType) queryString += `&TransactionType=${queries.TransactionType}`;
    if (queries?.searchText) queryString += `&SearchText=${queries.searchText}`;
    if (queries?.EndDate) queryString += `&EndDate=${queries.EndDate}`;
    if (queries?.StartDate) queryString += `&StartDate=${queries.StartDate}`;

  return AxiosInstance('GET', TRANSACTIONS + queryString,{}, token)
};

export const updatePrices = (body, token) => {
    return AxiosInstance("PUT", PRICES, {body}, token)
};

export const updateDiscounts = (body, token) => {
    return AxiosInstance("PUT", DISCOUNTS, {body}, token)
};