import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DatesCalendar = ({date, setDate, onClose}) => {

    const onDateChange = (newDate) => {
        setDate(newDate);
        onClose();
    };

    return (
        <DatePicker
            selected={date}
            showTimeSelect
            onChange={onDateChange}
            timeFormat="HH:mm"
            timeIntervals
            timeCaption="time"
            dateFormat="MMMM d, yyyy HH:mm aa"
        />
    );
};

export default DatesCalendar;