import React, {useEffect} from 'react';
import {dispatch, handleSort, translation} from "../../../helpers";
import {
    globalSelector,
    setDispensers,
    setLoader,
    setObjFilter,
    setStationGroups,
    setStations
} from "../../../redux/slices/globalSlice";
import {getDispensers, getStationGroups, getStations} from "../../../helpers/apis";
import {useSelector} from "react-redux";

const DispensersContent = ({openPopup}) => {
    const {token, dispensersData} = useSelector(globalSelector);

    useEffect(() => {
        dispatch(setLoader(true));
        dispatch(setObjFilter({}));
        getDispensers(token)
            .then(({data}) => dispatch(setDispensers(data)))
            .then(()=> getStationGroups(token, {pageSize: '300'})
                .then(({data}) => {
                    dispatch(setStationGroups(data));
                    getStations({StationGroupId: Number(data?.list[0]?.id)}, token, {pageSize: '300'})
                        .then(({data}) => {
                            dispatch(setStations(data));
                        })
                })
                .finally(() => dispatch(setLoader(false))))
    }, []);

    return (
        <div className="custom-list-container">
            <table>
                <thead>
                <tr>
                    <th colSpan={2} onClick={() => handleSort('id')}>
                        <p>
                            <i className='icon-next-to-id id-icon'></i>
                            <span>ID</span>
                        </p>
                    </th>
                    <th colSpan={2} onClick={() => handleSort('YandexDispenserId')} title={translation("Dispenser ID")}>
                        <p>
                            <i className="icon-order id-icon"/>
                            {translation("Dispenser ID")}
                        </p>
                    </th>
                    <th colSpan={2} onClick={() => handleSort('SerialNumber')} title={translation("Serial Number")}>
                        <p>
                            <i className="icon-order id-icon"/>
                            {translation("Serial Number")}
                        </p>
                    </th>
                    <th colSpan={2} onClick={() => handleSort('StationName')}
                        title={translation("Station Name")}>
                        <p>
                            <i className='icon-order id-icon'></i>
                            {translation("Station Name")}
                        </p>
                    </th>
                    <th colSpan={2} onClick={() => handleSort('StationGroupName')}
                        title={translation("Station Group Name")}>
                        <p>
                            <i className='icon-order id-icon'></i>
                            {translation("Station Group Name")}
                        </p>
                    </th>
                    <th colSpan={2} title={translation("Address")}>
                        <p>
                            {/*<i className="icon-order id-icon"/>*/}
                            {translation("Address")}
                        </p>
                    </th>
                    <th colSpan={2}
                        title={translation("Fuel Types")}>
                        <p>
                            {translation("Fuel Types")}
                        </p>
                    </th>
                    <th colSpan={2}><p>{translation("Actions")}</p></th>
                </tr>
                </thead>
                <tbody>
                {dispensersData?.list?.map((product) => (
                    <tr key={product.id}>
                        <td colSpan={2} title={product.id}><p>{product.id}</p></td>
                        <td colSpan={2} title={product.yandexDispenserId}><p>{product.yandexDispenserId}</p></td>
                        <td colSpan={2} title={product.serialNumber}><p>{product.serialNumber}</p></td>
                        <td colSpan={2} title={product.station.name}><p>{product.station.name}</p></td>
                        <td colSpan={2} title={product.station.stationGroup.name}><p>{product.station.stationGroup.name}</p></td>
                        <td colSpan={2} title={product.station.address}><p>{product.station.address}</p></td>
                        <td colSpan={2}>
                            <table>
                                <tbody>
                                {product?.fuelTypes?.map((fuelType, index) => (
                                    <tr key={index}>
                                        <td><p>{fuelType.name} ({fuelType.countType})</p></td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </td>
                        <td colSpan={2}>
                            <div onClick={() => openPopup("EditDispenser", product)}>
                                <p>
                                    <i className="icon-edit edit-icon"/>
                                </p>
                            </div>
                            {/*<div onClick={() => openPopup("DeleteDispenser", product)}><i className="icon-delete delete-icon"/></div>*/}
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default DispensersContent;