import i18n from "../i18next/i18next";
import {store} from '../redux/store'
import common_en from "../i18next/translations/en/common";
import common_am from "../i18next/translations/am/common";
import common_ru from "../i18next/translations/rus/common";
import {
    setDispensers,
    setFrontValidation,
    setFuelTypes,
    setLoader,
    setObjFilter,
    setStationGroups,
    setStations,
    setToStations,
    setTransactions,
    setUsers
} from "../redux/slices/globalSlice";
import {
    addNewDispensers,
    addNewFuelTypes,
    addNewStation,
    addNewStationGroups,
    addNewUser,
    deleteDispenserApi,
    deleteFuelTypes,
    deleteStationGroups,
    deleteStationsApi,
    deleteUser,
    editDispensers,
    editFuelTypes,
    editStationGroups,
    editStations,
    editUsers,
    EXPORT_CSV,
    EXPORT_CSV_STATIONS,
    EXPORT_CSV_TRANSACTIONS,
    EXPORT_CSV_SUMMARY,
    getDispensers,
    getFuelTypes,
    getStationGroups,
    getStations,
    getTransactions,
    getUsers,
    updatePrices,
    updateDiscounts,
} from "./apis";
import * as moment from "moment-timezone";

const sortingOrders = {};

export const dispatch = (action) => {
    store.dispatch(action)
};

export const translation = (key) => {
    return i18n.t(key)
};

export const resources = {
    en: {translation: common_en},
    am: {translation: common_am},
    ru: {translation: common_ru}
};

export const currentTimeInArmeniaStart = (date) => {
    if (date) {
        return moment.utc(date).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    } else {
        return moment.utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    }
};

export const currentTimeInArmeniaEnd = (date) => {
    if (date) {
        return moment.utc(date).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    } else {
        return moment.utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    }
};

export const formatDateString = (input) => {
    const [year, month, day] = input.split('-');
    const formattedDay = day?.length === 1 ? '0' + day : day;

    return `${year}-${month}-${formattedDay}`;
};

export const isValidDate = (dateString) => {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    return regex.test(dateString);
};

export const languages = Object.entries(resources).map(([lang]) => lang);

export const removeLngPrefix = (pathname) => {
    for (let lang of languages) {
        if (pathname.startsWith(`/${lang}/`) || pathname === `/${lang}`) {
            return pathname.replace(`/${lang}`, '')
        }
    }
    return pathname
};

export const serializableValue = (value) => {
    if (value !== null || undefined)
        return JSON.parse(JSON.stringify(value))
};

export const isError = () => {
    if (store.getState().global.error?.data?.Errors) {
        return store.getState().global.error?.data?.Errors[0]?.Message
    }
    if (store.getState().global.error?.data?.errors) {
        return store.getState().global.error?.data?.errors[0]?.message
    }
};

export const deleteFuel = (id, token) => {
    const {ObjFilter} = store.getState().global;

    dispatch(setLoader(true));
    return deleteFuelTypes(token, id)
        .then(() => getFuelTypes(token, {
            field: ObjFilter.field,
            pageIndex: ObjFilter.pageIndex,
            orderBy: ObjFilter.orderBy
        })
            .then(({data}) => dispatch(setFuelTypes(data)))
            .finally(() => dispatch(setLoader(false))))
};

export const deleteStationGroup = (id, token) => {
    const {ObjFilter} = store.getState().global;

    dispatch(setLoader(true));
    return deleteStationGroups(token, id)
        .then(() => getStationGroups(token, {
            field: ObjFilter.field,
            pageIndex: ObjFilter.pageIndex,
            orderBy: ObjFilter.orderBy,
            searchText: ObjFilter.searchText
        })
            .then(({data}) => dispatch(setStationGroups(data)))
            .finally(() => dispatch(setLoader(false))))
};

export const deleteStations = (id, stationGroupId, token) => {
    const {ObjFilter} = store.getState().global;

    dispatch(setLoader(true));
    return deleteStationsApi(token, id)
        .then(() => getStations({StationGroupId: stationGroupId}, token, {
            field: ObjFilter.field,
            pageIndex: ObjFilter.pageIndex,
            orderBy: ObjFilter.orderBy,
            searchText: ObjFilter.searchText
        })
            .then(({data}) => dispatch(setStations(data)))
            .finally(() => dispatch(setLoader(false))))
};

export const deleteDispenser = (id, token) => {
    const {ObjFilter} = store.getState().global;

    dispatch(setLoader(true));
    return deleteDispenserApi(token, id)
        .then(() => getDispensers(token, {
            field: ObjFilter.field,
            pageIndex: ObjFilter.pageIndex,
            orderBy: ObjFilter.orderBy,
            searchText: ObjFilter.searchText
        })
            .then(({data}) => dispatch(setDispensers(data)))
            .finally(() => dispatch(setLoader(false))))
};


export const deleteUsersApi = (id, token) => {
    const {ObjFilter} = store.getState().global;

    dispatch(setLoader(true));
    return deleteUser(id, token)
        .then(() => getUsers({}, token, {pageIndex: ObjFilter.pageIndex})
            .then(({data}) => dispatch(setUsers(data)))
            .finally(() => dispatch(setLoader(false))));

};

export const handleSubmit = async (
    popupType,
    popupContent,
    inputsData,
    selectedOption,
    selectedStationUsers,
    selectedStationDispensers,
    selectedGroupDispensers,
    selectedGroupUsers,
    setSelectedStationDispensers,
    setSelectedStationGroupDispensers,
    setSelectedStationGroupUsers,
    selectedFuelsDispensers,
    selectedFuelsStations,
    checkedIds,
    token,
    role,
    closeModal,
    toStations,
    fuelTypePrices,
    fuelTypeDiscounts
) => {

    const {stationsData, ObjFilter} = store.getState().global;
    const mergedAndUniqueFuelTypesArray = mergeAndRemoveDuplicates(stationsData?.list);

    const initialPrices = {};
    mergedAndUniqueFuelTypesArray.forEach(item => {
        initialPrices[item.id] = item.price;
    });

    const changedPricesObj = Object.entries(fuelTypePrices).reduce((acc, [key, val]) => {
        if (initialPrices[key] !== val) {
            acc[key] = val;
        }
        return acc;
    }, {});

    const initialDiscounts = {};
    mergedAndUniqueFuelTypesArray.forEach(item => {
        initialPrices[item.id] = item.discount;
    });

    const changedDiscountsObj = Object.entries(fuelTypeDiscounts).reduce((acc, [key, val]) => {
        if (initialDiscounts[key] !== val) {
            acc[key] = val;
        }
        return acc;
    }, {});

    if (formFrontValidation(inputsData, popupType)) {
        dispatch(setLoader(true));

        switch (popupType) {
            case "EditFuelType":
                await editFuelTypes({
                    "id": popupContent.id,
                    "name": inputsData.name || popupContent.name,
                    "yandexFuelTypeId": inputsData.yandexFuelTypeId || popupContent.yandexFuelTypeId,
                    "adgCode": inputsData.adgCode || popupContent.adgCode,
                    "departmentId": inputsData.departmentId || popupContent.departmentId,
                    "countType": selectedOption || popupContent.countType
                }, token);

                await getFuelTypes(token, {
                    field: ObjFilter.field,
                    pageIndex: ObjFilter.pageIndex,
                    orderBy: ObjFilter.orderBy
                })
                    .then(({data}) => dispatch(setFuelTypes(data)))
                    .finally(() => dispatch(setLoader(false)));
                break;

            case "AddNewFuelType":
                await addNewFuelTypes({
                    "name": inputsData.name,
                    "yandexFuelTypeId": inputsData.yandexFuelTypeId,
                    "adgCode": inputsData.adgCode || popupContent.adgCode,
                    "departmentId": inputsData.departmentId || popupContent.departmentId,
                    "countType": selectedOption || "kg"
                }, token);

                await getFuelTypes(token, {
                    field: ObjFilter.field,
                    pageIndex: ObjFilter.pageIndex,
                    orderBy: ObjFilter.orderBy
                })
                    .then(({data}) => dispatch(setFuelTypes(data)))
                    .finally(() => dispatch(setLoader(false)));
                break;

            case "DeleteFuelType":
                await deleteFuel(popupContent.id, token);
                break;

            case "DeleteStationGroups":
                await deleteStationGroup(popupContent.id, token);
                break;

            case "DeleteStations":
                await deleteStations(popupContent.id, popupContent?.stationGroup?.id, token);
                break;

            case "AddNewDispenser":
                if (selectedStationDispensers) {
                    await addNewDispensers({
                        "yandexDispenserId": inputsData.yandexDispenserId,
                        "serialNumber": inputsData.serialNumber,
                        "stationId": selectedStationDispensers,
                        "fuelTypes": selectedFuelsDispensers
                    }, token);

                    await getDispensers(token, {
                        field: ObjFilter.field,
                        pageIndex: ObjFilter.pageIndex,
                        orderBy: ObjFilter.orderBy,
                        searchText: ObjFilter.searchText
                    })
                        .then(({data}) => dispatch(setDispensers(data)))
                        .finally(() => dispatch(setLoader(false)));

                    setSelectedStationDispensers("");
                    setSelectedStationGroupDispensers("");
                    break;
                } else {
                    dispatch(setLoader(false));
                    return null
                }

            case "EditDispenser":
                await editDispensers({
                    "yandexDispenserId": inputsData.yandexDispenserId || popupContent.yandexDispenserId,
                    "serialNumber": inputsData.serialNumber || popupContent.serialNumber,
                    "stationId": selectedStationDispensers || popupContent.station.id,
                    "fuelTypes": selectedFuelsDispensers,
                    "id": popupContent.id
                }, token);

                await getDispensers(token, {
                    field: ObjFilter.field,
                    pageIndex: ObjFilter.pageIndex,
                    orderBy: ObjFilter.orderBy,
                    searchText: ObjFilter.searchText
                })
                    .then(({data}) => dispatch(setDispensers(data)))
                    .finally(() => dispatch(setLoader(false)));

                setSelectedStationDispensers("");
                setSelectedStationGroupDispensers("");
                break;

            case "DeleteDispenser":
                await deleteDispenser(popupContent?.id, token);
                break;

            case "DeleteUser":
                await deleteUsersApi(popupContent?.id, token);
                break;

            case "AddNewStationGroup":

                await addNewStationGroups({
                    "name": inputsData.name,
                    "address": inputsData.address,
                    "phoneNumber": inputsData.phoneNumber
                }, token);

                await getStationGroups(token, {
                    field: ObjFilter.field,
                    pageIndex: ObjFilter.pageIndex,
                    orderBy: ObjFilter.orderBy,
                    searchText: ObjFilter.searchText
                })
                    .then(({data}) => dispatch(setStationGroups(data)))
                    .finally(() => dispatch(setLoader(false)));

                break;

            case "AddNewStations":

                await addNewStation({
                    "yandexStationId": inputsData.yandexStationId,
                    "name": inputsData.name,
                    "address": inputsData.address,
                    "tin": inputsData.tin,
                    "phoneNumber": inputsData.phoneNumber,
                    "fuelTypes": selectedFuelsStations,
                    "stationGroupId": inputsData.stationGroupId,
                    "lat": inputsData.lat,
                    "long": inputsData.long
                }, token);

                await getStations({StationGroupId: toStations.product.id}, token, {
                    field: ObjFilter.field,
                    pageIndex: ObjFilter.pageIndex,
                    orderBy: ObjFilter.orderBy,
                    searchText: ObjFilter.searchText
                })
                    .then(({data}) => dispatch(setStations(data)))
                    .then(() => dispatch(setToStations({
                        active: true,
                        product: {
                            ...toStations.product,
                            id: Number(inputsData.stationGroupId),
                        }
                    })))
                    .finally(() => dispatch(setLoader(false)));

                break;

            case "EditStations":

                await editStations({
                    "yandexStationId": inputsData.yandexStationId || popupContent.yandexStationId,
                    "name": inputsData.name || popupContent.name,
                    "address": inputsData.address || popupContent.address,
                    "tin": inputsData.tin || popupContent.tin,
                    "phoneNumber": inputsData.phoneNumber || popupContent.phoneNumber,
                    "fuelTypes": selectedFuelsStations,
                    "stationGroupId": inputsData.stationGroupId || popupContent?.stationGroup?.id,
                    "lat": inputsData.lat || popupContent.lat,
                    "long": inputsData.long || popupContent.long,
                    "id": popupContent.id,
                }, token);

                await getStations({StationGroupId: toStations.product.id}, token, {
                    field: ObjFilter.field,
                    pageIndex: ObjFilter.pageIndex,
                    orderBy: ObjFilter.orderBy,
                    searchText: ObjFilter.searchText
                })
                    .then(({data}) => dispatch(setStations(data)))
                    .finally(() => dispatch(setLoader(false)));

                break;

            case "EditStationGroups":
                await editStationGroups({
                    "id": popupContent.id,
                    "name": inputsData.name || popupContent.name,
                    "address": inputsData.address || popupContent.address,
                    "phoneNumber": inputsData.phoneNumber || popupContent.phoneNumber
                }, token);

                await getStationGroups(token, {
                    field: ObjFilter.field,
                    pageIndex: ObjFilter.pageIndex,
                    orderBy: ObjFilter.orderBy
                })
                    .then(({data}) => dispatch(setStationGroups(data)))
                    .finally(() => dispatch(setLoader(false)));

                break;

            case "ConfirmFuelPrices" :
                await updatePrices({
                    "stationGroupId": inputsData.stationGroupId,
                    "fuelTypeIdToPrice": changedPricesObj,
                }, token);

                await getStations({StationGroupId: toStations.product.id}, token, {
                    field: ObjFilter.field,
                    pageIndex: ObjFilter.pageIndex,
                    orderBy: ObjFilter.orderBy,
                    searchText: ObjFilter.searchText
                })
                    .then(({data}) => dispatch(setStations(data)))
                    .finally(() => dispatch(setLoader(false)));

                break;

            case "ConfirmFuelPricesStation" :
                await updatePrices({
                    "stationGroupId": inputsData.stationGroupId,
                    "stationIds": [
                        Number(inputsData?.id)
                    ],
                    "fuelTypeIdToPrice": changedPricesObj
                }, token);

                await getStations({StationGroupId: toStations.product.id}, token, {
                    field: ObjFilter.field,
                    pageIndex: ObjFilter.pageIndex,
                    orderBy: ObjFilter.orderBy,
                    searchText: ObjFilter.searchText
                })
                    .then(({data}) => dispatch(setStations(data)))
                    .finally(() => dispatch(setLoader(false)));

                break;

            case "ConfirmFuelDiscounts" :
                await updateDiscounts({
                    "stationGroupId": inputsData.stationGroupId,
                    "fuelTypeIdToDiscount": changedDiscountsObj,
                }, token);

                await getStations({StationGroupId: toStations.product.id}, token, {
                    field: ObjFilter.field,
                    pageIndex: ObjFilter.pageIndex,
                    orderBy: ObjFilter.orderBy,
                    searchText: ObjFilter.searchText
                })
                    .then(({data}) => {
                        dispatch(setStations(data))
                    })
                    .finally(() => dispatch(setLoader(false)));

                break;

            case "AddNewUser":
                await addNewUser(role === "FuelSupervisor" ? {
                    "firstName": inputsData.firstName,
                    "lastName": inputsData.lastName,
                    "phoneNumber": inputsData.phoneNumber,
                    "email": inputsData.email,
                    "roleType": role,
                    "stationGroupId": selectedGroupUsers,
                    "stations": checkedIds
                } : {
                    "firstName": inputsData.firstName,
                    "lastName": inputsData.lastName,
                    "phoneNumber": inputsData.phoneNumber,
                    "email": inputsData.email,
                    "roleType": role,
                }, token);

                await getUsers({}, token)
                    .then(({data}) => dispatch(setUsers(data)))
                    .finally(() => dispatch(setLoader(false)));
                break;

            case "EditUser":
                await editUsers(role === "FuelSupervisor" ? {
                    "id": inputsData.id,
                    "firstName": inputsData.firstName,
                    "lastName": inputsData.lastName,
                    "phoneNumber": inputsData.phoneNumber,
                    "email": inputsData.email,
                    "roleType": role,
                    "stationGroupId": selectedGroupUsers || popupContent?.stations[0]?.stationGroup?.id,
                    "stations": checkedIds
                } : {
                    "id": inputsData.id,
                    "firstName": inputsData.firstName,
                    "lastName": inputsData.lastName,
                    "phoneNumber": inputsData.phoneNumber,
                    "email": inputsData.email,
                    "roleType": role,
                }, token);

                await getUsers({}, token, {pageIndex: ObjFilter.pageIndex})
                    .then(({data}) => dispatch(setUsers(data)))
                    .finally(() => dispatch(setLoader(false)));
                break;

            default:
                return dispatch(setLoader(false))
        }
        closeModal();
    }
};

export const showResultsList = (newQuery, setResults) => {
    const {stationsData} = store.getState().global;

    const filteredResults = stationsData?.list?.filter(result =>
        result?.name?.toLowerCase().includes(newQuery.toLowerCase())
    );
    setResults(filteredResults);
};

export const mergeAndRemoveDuplicates = (stations) => {
    const uniqueFuelTypesSet = new Set();

    stations?.forEach((station) => {
        if (station?.fuelTypes && Array.isArray(station?.fuelTypes)) {
            station.fuelTypes.forEach((fuelType) => {
                uniqueFuelTypesSet.add(JSON.stringify(fuelType));
            });
        }
    });

    return Array.from(uniqueFuelTypesSet, (jsonString) =>
        JSON.parse(jsonString)
    );
};

export const formFrontValidation = (inputsData, popupType) => {
    const {selectedChoice, toStations} = store.getState().global;
    const allowedPopupTypes = ["AddNewFuelType", "AddNewDispenser", "AddNewStations", "AddNewUser", "AddNewStationGroup"];
    const errors = {};

    if (!allowedPopupTypes.includes(popupType)) {
        return true;
    }

    dispatch(setFrontValidation({}));

    switch (selectedChoice) {
        case "Fuel Types":
            if (inputsData.yandexFuelTypeId === "") {
                errors.yandexFuelTypeId = translation("Yandex Fuel Type ID is required");
            }
            if (inputsData.name === "") {
                errors.name = translation("Name is required");
            }
            if (inputsData.adgCode === "") {
                errors.adgCode = translation("Adg code is required");
            }
            if (inputsData.departmentId === "") {
                errors.departmentId = translation("Department Id is required");
            }
            break;

        case "Station Groups":
            if (toStations.active) {
                if (inputsData.tin === "" || inputsData.tin.length !== 8) {
                    errors.tin = translation("TIN must be 8 characters long");
                }
                if (inputsData.yandexStationId === "") {
                    errors.yandexStationId = translation("Yandex Station ID is required");
                }
                if (!inputsData.fuelTypes.length) {
                    errors.fuelTypes = translation("At least one fuel type must be selected");
                }
                if (inputsData.name === "") {
                    errors.name = translation("Station Name is required");
                }
            } else {
                if (inputsData.phoneNumber === "") {
                    errors.phoneNumber = translation("Phone number is required");
                }
                if (inputsData.address === "") {
                    errors.address = translation("Address is required");
                }
                if (inputsData.name === "") {
                    errors.name = translation("Name is required");
                }
            }
            break;

        case "Dispensers":
            if (inputsData.serialNumber === "") {
                errors.serialNumber = translation("Serial number is required");
            }
            break;

        case "Users":
            if (inputsData.email === "") {
                errors.email = translation("Email is required");
            }
            if (inputsData.firstName === "") {
                errors.firstName = translation("First name is required");
            }
            if (inputsData.lastName === "") {
                errors.lastName = translation("Last name is required");
            }
            break;

        default:
            break;
    }

    dispatch(setFrontValidation(errors));

    return Object.keys(errors).length === 0;
};

export const searchArray = () => {
    const {selectedChoice, fuelTypesData, toStations, stationsData, stationGroupsData, dispensersData, usersData, transactionsData} = store.getState().global;

    switch (selectedChoice) {
        case "Fuel Types":
            return fuelTypesData;
        case "Station Groups":
            if (toStations.active) {
                return stationsData
            } else {
                return stationGroupsData;
            }
        case "Fuel Prices":
            if (toStations.active) {
                return stationsData
            } else {
                return stationGroupsData;
            }
        case "Dispensers":
            return dispensersData;
        case "Users":
            return usersData;
        case "Transactions":
            return transactionsData;
        default:
            return []
    }
};

export const handleSort = (field, StartDate, EndDate) => {
    const {toStations, selectedTransactionType, selectedChoice, ObjFilter: objFilterCases, selectedFuelTypeOption, token} = store.getState().global;

    const transactionTypeParameter = selectedTransactionType !== "All" ? {TransactionType: selectedTransactionType} : {};

    if (!sortingOrders[field]) {
        sortingOrders[field] = "asc";
    } else {
        sortingOrders[field] = sortingOrders[field] === "asc" ? "desc" : "asc";
    }

    dispatch(setLoader(true));
    dispatch(setObjFilter({
        field,
        orderBy: sortingOrders[field],
        pageIndex: objFilterCases.pageIndex,
        searchText: objFilterCases.searchText,
        FuelTypeId: selectedFuelTypeOption,
        ...transactionTypeParameter,
        StartDate,
        EndDate
    }));

    switch (selectedChoice) {
        case "Fuel Types":
            return getFuelTypes(token, {field, orderBy: sortingOrders[field], pageIndex: objFilterCases.pageIndex})
                .then(({data}) => dispatch(setFuelTypes(data)))
                .finally(() => dispatch(setLoader(false)));

        case "Station Groups":
            if (toStations?.active) {
                return getStations({StationGroupId: toStations?.product?.id}, token, {
                    field,
                    orderBy: sortingOrders[field],
                    pageIndex: objFilterCases.pageIndex,
                    searchText: objFilterCases.searchText
                })
                    .then(({data}) => dispatch(setStations(data)))
                    .finally(() => dispatch(setLoader(false)));
            } else {
                return getStationGroups(token, {
                    field,
                    orderBy: sortingOrders[field],
                    pageIndex: objFilterCases.pageIndex,
                    searchText: objFilterCases.searchText
                })
                    .then(({data}) => dispatch(setStationGroups(data)))
                    .finally(() => dispatch(setLoader(false)));
            }
        case  "Dispensers":
            return getDispensers(token, {
                field,
                orderBy: sortingOrders[field],
                pageIndex: objFilterCases.pageIndex,
                searchText: objFilterCases.searchText
            })
                .then(({data}) => dispatch(setDispensers(data)))
                .finally(() => dispatch(setLoader(false)));
        case "Users":
            return getUsers({}, token, {
                field,
                orderBy: sortingOrders[field],
                pageIndex: objFilterCases.pageIndex,
                searchText: objFilterCases.searchText
            })
                .then(({data}) => dispatch(setUsers(data)))
                .finally(() => dispatch(setLoader(false)));

        case "Transactions":
            const params = {
                field,
                orderBy: sortingOrders[field],
                pageIndex: objFilterCases.pageIndex,
                searchText: objFilterCases.searchText,
                FuelTypeId: selectedFuelTypeOption,
                ...transactionTypeParameter,
                StartDate: StartDate || currentTimeInArmeniaStart(),
                EndDate: EndDate || currentTimeInArmeniaEnd()
            };

            dispatch(setObjFilter(params));

            return getTransactions(token, params)
                .then(({data}) => dispatch(setTransactions(data)))
                .finally(() => dispatch(setLoader(false)));

        case "Fuel Prices":
            return getStations({StationGroupId: toStations?.product?.id}, token, {
                field,
                orderBy: sortingOrders[field],
                pageIndex: objFilterCases.pageIndex,
                searchText: objFilterCases.searchText
            })
                .then(({data}) => dispatch(setStations(data)))
                .finally(() => dispatch(setLoader(false)));

        default:

            return null;
    }
};

export const exportAsCSVApi = (url, filename) => {
    const {token} = store.getState().global;

    const headers = new Headers();
    headers.append('Authorization', `Bearer ${token}`);

    fetch(url, {
        method: 'GET',
        headers: headers,
    })
        .then((response) => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.blob();
        })
        .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${filename}.xlsx`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
            console.error('There was a problem with the fetch operation:', error);
        });

};

export const exportAsCSV = () => {
    const {selectedChoice, ObjFilter, toStations} = store.getState().global;

    let queryString = "?";

    if (ObjFilter?.field) queryString += `&OrderBy=${ObjFilter.field}`;
    if (ObjFilter?.orderBy) queryString += `&OrderDir=${ObjFilter.orderBy}`;
    if (ObjFilter?.searchText) queryString += `&SearchText=${ObjFilter.searchText}`;
    if (toStations?.product?.id) queryString += `&StationGroupId=${toStations?.product?.id}`;

    if (searchArray()?.list?.length) {
        switch (selectedChoice) {
            case "Fuel Types":
                return exportAsCSVApi(`${EXPORT_CSV}?downloadType=FuelType`, "FuelType");
            case "Station Groups":
                if (toStations?.active) {
                    return exportAsCSVApi(`${EXPORT_CSV_STATIONS + queryString}`, "Stations")
                } else {
                    return exportAsCSVApi(`${EXPORT_CSV}?downloadType=StationGroup`, "StationGroups")
                }
            case "Fuel Prices":
                return exportAsCSVApi(`${EXPORT_CSV_STATIONS + queryString}`, "Stations");
            case "Dispensers":
                return exportAsCSVApi(`${EXPORT_CSV}?downloadType=Dispenser`, "Dispensers");
            // case "Users":
            //     return exportAsCSVApi(`${EXPORT_CSV}?downloadType=Users`, "Users");

            default:
                return null;
        }
    }
};

export const exportAsCsvTransactions = () => {
    const {ObjFilter} = store.getState().global;

    if (searchArray()?.list?.length) {
        const objectToQueryString = (obj) => {
            return Object.entries(obj)
                .filter(([key, value]) => value !== undefined && value !== '')
                .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
                .join('&');
        };

        const outputObject = {
            "OrderBy": ObjFilter.field,
            "OrderDir": ObjFilter.orderBy,
            "TransactionType": ObjFilter.TransactionType,
            // "pageIndex": ObjFilter.pageIndex,
            "StartDate": ObjFilter.StartDate,
            "EndDate": ObjFilter.EndDate
        };

        if (ObjFilter?.FuelTypeId) {
            outputObject["FuelTypeId"] = ObjFilter.FuelTypeId
        }

        const queryString = objectToQueryString(outputObject);

        return exportAsCSVApi(`${EXPORT_CSV_TRANSACTIONS}?${queryString}`, "Transactions");

    }
};

export const exportAsCsvSummary = () => {
    const {ObjFilter} = store.getState().global;

    if (searchArray()?.list?.length) {
        const objectToQueryString = (obj) => {
            return Object.entries(obj)
                .filter(([key, value]) => value !== undefined && value !== '')
                .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
                .join('&');
        };

        const outputObject = {
            "StartDate": ObjFilter.StartDate,
            "EndDate": ObjFilter.EndDate
        };

        const queryString = objectToQueryString(outputObject);

        return exportAsCSVApi(`${EXPORT_CSV_SUMMARY}?${queryString}`, "Summary_report");

    }
};

export const getByPageNumber = (PageIndex, dateStart, dateEnd) => {
    const {token, selectedChoice, toStations, ObjFilter, selectedFuelTypeOption, selectedTransactionType} = store.getState().global;

    const stationsActive = toStations?.active;
    const activeStationProductId = toStations?.product?.id;

    const transactionTypeParameter = selectedTransactionType !== "All" ? {TransactionType: selectedTransactionType} : {};

    switch (selectedChoice) {
        case "Fuel Types":
            return getFuelTypes(token, {
                field: ObjFilter.field,
                pageIndex: PageIndex,
                orderBy: ObjFilter.orderBy
            })
                .then(({data}) => dispatch(setFuelTypes(data)))
                .finally(() => dispatch(setLoader(false)));
        case "Station Groups":
            if (stationsActive) {
                return getStations({StationGroupId: activeStationProductId}, token, {
                    field: ObjFilter.field,
                    pageIndex: PageIndex,
                    orderBy: ObjFilter.orderBy,
                    searchText: ObjFilter.searchText
                })
                    .then(({data}) => dispatch(setStations(data)))
                    .finally(() => dispatch(setLoader(false)));
            } else {
                return getStationGroups(token, {
                    field: ObjFilter.field,
                    pageIndex: PageIndex,
                    orderBy: ObjFilter.orderBy,
                    searchText: ObjFilter.searchText
                })
                    .then(({data}) => dispatch(setStationGroups(data)))
                    .finally(() => dispatch(setLoader(false)));
            }
        case "Fuel Prices":
            return getStations({StationGroupId: activeStationProductId}, token, {
                field: ObjFilter.field,
                pageIndex: PageIndex,
                orderBy: ObjFilter.orderBy,
                searchText: ObjFilter.searchText
            })
                .then(({data}) => dispatch(setStations(data)))
                .finally(() => dispatch(setLoader(false)));
        case "Dispensers":
            return getDispensers(token, {
                field: ObjFilter.field,
                pageIndex: PageIndex,
                orderBy: ObjFilter.orderBy,
                searchText: ObjFilter.searchText
            })
                .then(({data}) => dispatch(setDispensers(data)))
                .finally(() => dispatch(setLoader(false)));
        case "Users":
            return getUsers({}, token, {
                field: ObjFilter.field,
                pageIndex: PageIndex,
                orderBy: ObjFilter.orderBy
            })
                .then(({data}) => dispatch(setUsers(data)))
                .finally(() => dispatch(setLoader(false)));
        case "Transactions":
            const params = {
                field: ObjFilter.field,
                pageIndex: PageIndex,
                orderBy: ObjFilter.orderBy,
                searchText: ObjFilter.searchText,
                FuelTypeId: selectedFuelTypeOption,
                ...transactionTypeParameter,
                StartDate: currentTimeInArmeniaStart(dateStart),
                EndDate: currentTimeInArmeniaEnd(dateEnd),
            };

            dispatch(setObjFilter(params));

            return getTransactions(token, params)
                .then(({data}) => dispatch(setTransactions(data)))
                .finally(() => dispatch(setLoader(false)));

        default:
            return null;

    }
};