import React from 'react';
import {translation} from "../../../../../helpers";

const EditFuelTypes = ({handleSubmit, popupContent, inputsData, handleChange, handleDropdownChange, selectedOption, closeModal}) => {
    return (
        <div className="custom-modal">
            <div className="custom-modal-content">
                <h3 className="custom-modal-title">{translation("Edit Fuel")}</h3>
                <form onSubmit={handleSubmit}>
                    <div className="input-container">
                        <label htmlFor="input1">{translation("Fuel Name")}</label>
                        <input autoComplete="off" type="text" name="name" value={inputsData.name}
                               placeholder={popupContent.name}
                               onChange={handleChange}/>
                    </div>

                    <div className="input-container">
                        <label htmlFor="input1">{translation("Adg code")}</label>
                        <input autoComplete="off" type="text" name="adgCode" value={inputsData.adgCode}
                               placeholder={popupContent.adgCode}
                               onChange={handleChange}/>
                    </div>

                    <div className="input-container">
                        <label htmlFor="input1">{translation("Department Id")}</label>
                        <input autoComplete="off" type="text" name="departmentId" value={inputsData.departmentId}
                               placeholder={popupContent.departmentId}
                               onChange={handleChange}/>
                    </div>

                    <div className="input-container">
                        <label htmlFor="input2">{translation("Yandex ID")}</label>
                        <input autoComplete="off" type="text" name="yandexFuelTypeId"
                               value={inputsData.yandexFuelTypeId} placeholder={popupContent.yandexFuelTypeId}
                               onChange={handleChange}/>
                    </div>

                    <div className="input-container">
                        <label htmlFor="dropdown">{translation("Select unit of measurement")}</label>
                        <select id="dropdown" value={selectedOption}
                                onChange={handleDropdownChange}>
                            <option value="Kg">Kg</option>
                            <option value="L">Ltr</option>
                        </select>
                    </div>

                    <div className="buttons-container">
                        <button className="custom-popup-submit-button" type="submit"
                                onClick={handleSubmit}>{translation("Save")}</button>
                        <button className="custom-popup-cancel-button" type="button"
                                onClick={closeModal}>{translation("Cancel")}</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditFuelTypes;