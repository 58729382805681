import axios from 'axios';
import {dispatch, serializableValue, translation} from "../index";
import {resetState, setError, setLoader} from "../../redux/slices/globalSlice";

export const AxiosInstance = (method, url, params = {}, authToken) => {
    const regex = /.*\/login.*/;
    const containsLoginText = regex.test(url);

    const { body, headers } = params;

    return new Promise(async (resolve) => {
        const timeoutPromise = new Promise((_, reject) => {
            setTimeout(() => {
                reject(new Error('Request timeout'));
            }, 6000);
        });

        const { data } = await Promise.race([
            axios({
                method,
                url,
                data: body || {},
                headers: headers || {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${authToken}`
                }
            }),
            timeoutPromise
        ]).catch(async error => {
            if (error instanceof Error && error.message === 'Request timeout') {
                await dispatch(setError(serializableValue({data: {Errors: [{Message: translation("Something went wrong, Try again later")}]}})));
                await dispatch(setLoader(false))
            } else if (error?.response) {
                await dispatch(setError(serializableValue(error.response)));
                await dispatch(setLoader(false))
            }
            if (error?.response?.status === 401 && !containsLoginText) {
                window.location.href = "/login";
                dispatch(resetState());
            }
            return { data: null };
        });

        if (data) {
            try {
                const result = await data;
                resolve(result);
            } catch (error) {
                const result = await data.error;
                resolve(result);
            }
        }
    });
};