import React from 'react';
import ReactPaginate from 'react-paginate';
import {useSelector} from 'react-redux';
import {dispatch, getByPageNumber} from '../../../helpers';
import {globalSelector, setLoader, setObjFilter} from "../../../redux/slices/globalSlice";

const Pagination = ({totalItems, itemsPerPage, setCurrentPage, currentPage, dateStart, dateEnd}) => {
    const {loading, ObjFilter, selectedTransactionType, selectedFuelTypeOption} = useSelector(globalSelector);
    const totalPages = Math.max(Math.ceil(totalItems / itemsPerPage), 1);
    const transactionTypeParameter = selectedTransactionType !== "All" ? { TransactionType: selectedTransactionType } : {};

    const handlePageClick = (selectedPage) => {
        dispatch(setLoader(true));
        dispatch(
            setObjFilter({
                field: ObjFilter.field,
                orderBy: ObjFilter.orderBy,
                pageIndex: selectedPage.selected + 1,
                ...transactionTypeParameter,
                FuelTypeId: selectedFuelTypeOption,
            })
        );
        getByPageNumber(selectedPage.selected + 1, dateStart, dateEnd );
        setCurrentPage(selectedPage.selected + 1);
    };

    if (loading) {
        return null;
    }

    return (
        <>
            {
                totalPages > 1 &&
                <ReactPaginate
                    previousLabel={<i className="icon-chevron-left"/>}
                    nextLabel={<i className="icon-chevron-right"/>}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={totalPages}
                    marginPagesDisplayed={4}
                    pageRangeDisplayed={4}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                    pageClassName={'page-number'}
                    forcePage={currentPage - 1}
                />
            }
        </>

    );
};

export default Pagination;