import React from 'react';
import {useHistory} from "react-router-dom"
import {useTranslation} from "react-i18next";
import {dispatch, translation} from "../../../helpers";
import {
    globalSelector, resetState,
} from "../../../redux/slices/globalSlice";
import {useSelector} from "react-redux";

const Header = () => {
    const {loggedInPerson} = useSelector(globalSelector);
    const {i18n} = useTranslation();
    const history = useHistory();

    const handleLanguageChange = (language) => {
        i18n.changeLanguage(language);
    };

    const logout = () => {
        dispatch(resetState());
        history.push('/login')
    };

    return (
        <div className="header">
            <button className="lang-btn" onClick={() => handleLanguageChange('en')}>eng</button>
            <button className="lang-btn" onClick={() => handleLanguageChange('am')}>arm</button>
            <button className="lang-btn" onClick={() => handleLanguageChange('ru')}>rus</button>
            <i className='icon-person'></i>
            <p className="my-account-text">{loggedInPerson}</p>
            <span className="separator">|</span>
            <button className="logout-btn" onClick={logout}>{translation("Logout")}</button>
            <i className="icon-logout logout-logo" onClick={logout}/>
        </div>
    );
};

export default Header;