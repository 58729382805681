import React from 'react';
import {useSelector} from "react-redux/es/index";
import {dispatch, isError} from "../../helpers";
import {globalSelector, setError} from "../../redux/slices/globalSlice";

const Error = () => {
    const {error} = useSelector(globalSelector);

    return (
        <div className="popup-background">
            <div className="popup-container">
                <div className="popup-header">
                    <h2>{error?.statusText}</h2>
                    <button className="popup-close" onClick={()=> dispatch(setError({}))}>X</button>
                </div>
                <div className="popup-content">
                    <p>{isError()}</p>
                </div>
            </div>
        </div>
    );
};

export default Error;