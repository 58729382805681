import React, {useEffect, useRef, useState} from 'react';
import {
    currentTimeInArmeniaEnd,
    currentTimeInArmeniaStart,
    dispatch,
    formatDateString,
    isValidDate
} from "../../../helpers";
import {useSelector} from "react-redux";
import {
    globalSelector,
    setDispensers,
    setFuelTypes,
    setLoader,
    setObjFilter,
    setSelectedFuelTypeOption,
    setSelectedTransactionType,
    setStationGroups,
    setStations,
    setTransactions,
    setUsers
} from "../../../redux/slices/globalSlice";
import {
    getDispensers,
    getFuelTypes,
    getStationGroups,
    getStations,
    getTransactions,
    getUsers
} from "../../../helpers/apis";
import moment from "moment";
import FuelTypes from "./FuelTypes";
import Stations from "./Stations";
import Dispensers from "./Dispensers";
import Users from "./Users";
import Transactions from "./Transactions";
import FuelPrices from "./FuelPrices";

const AddNew = ({openPopup, dateEnd, dateStart, setDateStart, setDateEnd}) => {
    const {selectedChoice, toStations, token, ObjFilter, selectedTransactionType, fuelTypesData, selectedFuelTypeOption} = useSelector(globalSelector);
    const [searchValue, setSearchValue] = useState("");
    const [showCalendarStart, setShowCalenderStart] = useState(false);
    const [showCalendarEnd, setShowCalenderEnd] = useState(false);
    const EndDateIso = currentTimeInArmeniaEnd(dateEnd);
    const StartDateIso = currentTimeInArmeniaStart(dateStart);
    const calendarStartRef = useRef(null);
    const calendarEndRef = useRef(null);

    useEffect(() => {
        if (selectedChoice === "Transactions") {
            dispatch(setLoader(true));
            dispatch(setObjFilter({}));
            getFuelTypes(token, {
                pageSize: '30',
            })
                .then(({data}) => dispatch(setFuelTypes(data)))
                .finally(() => dispatch(setLoader(false)))
        }
    }, [selectedChoice]);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (
                showCalendarStart &&
                calendarStartRef.current &&
                !calendarStartRef.current.contains(event.target)
            ) {
                setShowCalenderStart(false);
                if (isValidDate(formatDateString(StartDateIso))) {
                    setDateStart(moment(StartDateIso).format('ddd MMM DD YYYY HH:mm:ss [GMT]Z (zz)'))
                }
            }

            if (
                showCalendarEnd &&
                calendarEndRef.current &&
                !calendarEndRef.current.contains(event.target)
            ) {
                setShowCalenderEnd(false);
                if (isValidDate(formatDateString(EndDateIso))) {
                    setDateEnd(moment(EndDateIso).format('ddd MMM DD YYYY HH:mm:ss [GMT]Z (zz)'))
                }
            }
        };

        window.addEventListener('mousedown', handleOutsideClick);

        return () => {
            window.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [showCalendarStart, showCalendarEnd, StartDateIso, EndDateIso]);

    useEffect(() => {
        setSearchValue("");
    }, [selectedChoice]);

    const handleChangeFuelTypeId = (event) => {
        const selectedObj = fuelTypesData?.list?.find((item) => item.name === event.target.value);
        const transactionTypeParameter = selectedTransactionType !== "All" ? {TransactionType: selectedTransactionType} : {};

        if (event.target.value === "All") {
            dispatch(setSelectedFuelTypeOption(null));

            const params = {
                field: ObjFilter.field,
                pageIndex:
                ObjFilter.pageIndex,
                orderBy: ObjFilter.orderBy,
                searchText: searchValue,
                ...transactionTypeParameter,
                StartDate: StartDateIso,
                EndDate: EndDateIso
            };

            dispatch(setObjFilter(params));
            dispatch(setLoader(true));

            getTransactions(token, params)
                .then(({data}) => dispatch(setTransactions(data)))
                .finally(() => dispatch(setLoader(false)));
        } else {
            dispatch(setSelectedFuelTypeOption(selectedObj.id));

            const params = {
                field: ObjFilter.field,
                pageIndex: ObjFilter.pageIndex,
                orderBy: ObjFilter.orderBy,
                searchText: searchValue,
                ...transactionTypeParameter,
                FuelTypeId: selectedObj.id,
                StartDate: StartDateIso,
                EndDate: EndDateIso
            };

            dispatch(setObjFilter(params));
            dispatch(setLoader(true));

            getTransactions(token, params)
                .then(({data}) => dispatch(setTransactions(data)))
                .finally(() => dispatch(setLoader(false)));
        }
    };

    const handleSelectChange = (event) => {
        const transactionTypeParameter = event.target.value !== "All" ? {TransactionType: event.target.value} : {};

        dispatch(setSelectedTransactionType(event.target.value));

        const params = {
            field: ObjFilter.field, pageIndex:
            ObjFilter.pageIndex,
            orderBy: ObjFilter.orderBy,
            searchText: searchValue,
            FuelTypeId: selectedFuelTypeOption,
            ...transactionTypeParameter,
            StartDate: StartDateIso,
            EndDate: EndDateIso
        };

        dispatch(setObjFilter(params));
        dispatch(setLoader(true));

        getTransactions(token, params)
            .then(({data}) => dispatch(setTransactions(data)))
            .finally(() => dispatch(setLoader(false)));
    };

    const handleSearch = (e, value) => {
        e.preventDefault();
        const transactionTypeParameter = selectedTransactionType !== "All" ? {TransactionType: selectedTransactionType} : {};

        const inputValue = e.target.value;

        setSearchValue(inputValue || value);

        const commonParams = {
            field: ObjFilter.field,
            pageIndex: ObjFilter.pageIndex,
            orderBy: ObjFilter.orderBy,
            FuelTypeId: selectedFuelTypeOption,
            searchText: inputValue || value,
            ...transactionTypeParameter,
            StartDate: StartDateIso,
            EndDate: EndDateIso
        };

        dispatch(setObjFilter(commonParams));

        switch (selectedChoice) {
            case "Station Groups":

                if (inputValue?.length >= 2) {
                    dispatch(setLoader(true));

                    if (toStations.active) {
                        getStations({StationGroupId: toStations.product.id}, token, commonParams)
                            .then(({data}) => dispatch(setStations(data)))
                            .finally(() => dispatch(setLoader(false)));
                    } else {
                        getStationGroups(token, commonParams)
                            .then(({data}) => dispatch(setStationGroups(data)))
                            .finally(() => dispatch(setLoader(false)));
                    }
                } else if (inputValue?.length === 0) {
                    dispatch(setLoader(true));

                    if (toStations.active) {
                        getStations({StationGroupId: toStations.product.id}, token, commonParams)
                            .then(({data}) => dispatch(setStations(data)))
                            .finally(() => dispatch(setLoader(false)));
                    } else {
                        getStationGroups(token, commonParams)
                            .then(({data}) => dispatch(setStationGroups(data)))
                            .finally(() => dispatch(setLoader(false)));
                    }
                }
                break;

            case "Fuel Prices":

                if (inputValue?.length >= 2) {
                    dispatch(setLoader(true));

                    if (toStations.active) {
                        getStations({StationGroupId: toStations.product.id}, token, commonParams)
                            .then(({data}) => dispatch(setStations(data)))
                            .finally(() => dispatch(setLoader(false)));
                    } else {
                        getStationGroups(token, commonParams)
                            .then(({data}) => dispatch(setStationGroups(data)))
                            .finally(() => dispatch(setLoader(false)));
                    }
                } else if (inputValue?.length === 0) {
                    dispatch(setLoader(true));

                    if (toStations.active) {
                        getStations({StationGroupId: toStations.product.id}, token, commonParams)
                            .then(({data}) => dispatch(setStations(data)))
                            .finally(() => dispatch(setLoader(false)));
                    } else {
                        getStationGroups(token, commonParams)
                            .then(({data}) => dispatch(setStationGroups(data)))
                            .finally(() => dispatch(setLoader(false)));
                    }
                }

                break;
            case "Dispensers":
                if (inputValue?.length >= 2) {
                    dispatch(setLoader(true));
                    getDispensers(token, commonParams)
                        .then(({data}) => dispatch(setDispensers(data)))
                        .finally(() => dispatch(setLoader(false)));
                }
                if (inputValue?.length === 0) {
                    dispatch(setLoader(true));
                    getDispensers(token, commonParams)
                        .then(({data}) => dispatch(setDispensers(data)))
                        .finally(() => dispatch(setLoader(false)));
                }
                break;

            case "Users":
                if (inputValue?.length >= 2) {
                    dispatch(setLoader(true));
                    getUsers({}, token, {searchText: inputValue})
                        .then(({data}) => dispatch(setUsers(data)))
                        .finally(() => dispatch(setLoader(false)));
                }

                if (inputValue?.length === 0) {
                    dispatch(setLoader(true));
                    getUsers({}, token, {searchText: inputValue})
                        .then(({data}) => dispatch(setUsers(data)))
                        .finally(() => dispatch(setLoader(false)));
                }

                break;

            case "Transactions":
                if (inputValue?.length >= 2) {
                    dispatch(setLoader(true));
                    getTransactions(token, {
                        ...commonParams, ...{
                            StartDate: StartDateIso,
                            EndDate: EndDateIso
                        }
                    })
                        .then(({data}) => dispatch(setTransactions(data)))
                        .finally(() => dispatch(setLoader(false)));
                }

                if (inputValue?.length === 0) {
                    dispatch(setLoader(true));
                    getTransactions(token, {
                        ...commonParams, ...{
                            StartDate: StartDateIso,
                            EndDate: EndDateIso
                        }
                    })
                        .then(({data}) => dispatch(setTransactions(data)))
                        .finally(() => dispatch(setLoader(false)));
                }

                break;

            default:
                return null
        }
    };

    switch (selectedChoice) {
        case "Fuel Types":
            return (
                <FuelTypes openPopup={openPopup}/>
            );

        case "Station Groups":
            return (
                <Stations
                    handleSearch={handleSearch}
                    searchValue={searchValue}
                    openPopup={openPopup}
                />
            );
        case "Fuel Prices":
            return (
                <FuelPrices
                    handleSearch={handleSearch}
                    searchValue={searchValue}
                    openPopup={openPopup}
                />
            );
        case "Dispensers":
            return (
                <Dispensers
                    handleSearch={handleSearch}
                    searchValue={searchValue}
                    openPopup={openPopup}
                />
            );
        case "Users":
            return (
                <Users
                    handleSearch={handleSearch}
                    searchValue={searchValue}
                    openPopup={openPopup}
                />
            );
        case "Transactions":
            return (
                <Transactions
                    dateEnd={dateEnd}
                    dateStart={dateStart}
                    setDateEnd={setDateEnd}
                    searchValue={searchValue}
                    setDateStart={setDateStart}
                    handleSearch={handleSearch}
                    calendarEndRef={calendarEndRef}
                    calendarStartRef={calendarStartRef}
                    handleSelectChange={handleSelectChange}
                    setShowCalenderEnd={setShowCalenderEnd}
                    setShowCalenderStart={setShowCalenderStart}
                    handleChangeFuelTypeId={handleChangeFuelTypeId}

                />
            );
        default:
            return null
    }
};

export default AddNew;