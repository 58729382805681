import React, {useEffect} from 'react';
import {useSelector} from "react-redux";
import {getStations} from "../../../../../helpers/apis";
import {dispatch, translation} from "../../../../../helpers";
import {globalSelector, setLoader, setStations} from "../../../../../redux/slices/globalSlice";

const EditUser = ({
  handleSubmit,
  handleChange,
  inputsData,
  handleRoleChange,
  role,
  selectedGroupUsers,
  handleSelectStationGroup,
  stationGroupsData,
  popupContent,
  closeModal,
  searchStation,
  setSearchStation,
  setCheckedIds,
  filteredStations,
  setUserChecked,
  userChecked
}) => {
    const {token} = useSelector(globalSelector);

    useEffect(()=>{
        getStations({StationGroupId: Number(selectedGroupUsers)}, token, {pageSize: '300'})
            .then(({data}) => {
                dispatch(setStations(data));
            })
            .finally(() => dispatch(setLoader(false)))
    },[selectedGroupUsers]);

    const handleChangeUserStation = (stationId) => {
        setUserChecked((prevChecked) => {
            const updatedChecked = prevChecked.includes(stationId)
                ? prevChecked.filter((id) => id !== stationId)
                : [...prevChecked, stationId];

            setCheckedIds([...updatedChecked]);

            return updatedChecked;
        });
    };

    return (
        <div className="custom-modal" style={{overflow: "auto", maxHeight: "85vh"}}>
            <div className="custom-modal-content">
                <h3 className="custom-modal-title">{translation("Edit User")}</h3>
                <form onSubmit={handleSubmit}>
                    <div className="input-container">
                        <label htmlFor="input1">{translation("First Name")}</label>
                        <input autoComplete="off" type="text" name="firstName" value={inputsData.firstName}
                               onChange={handleChange}/>
                    </div>

                    <div className="input-container">
                        <label htmlFor="input2">{translation("Last Name")}</label>
                        <input autoComplete="off" type="text" name="lastName" value={inputsData.lastName}
                               onChange={handleChange}/>
                    </div>

                    <div className="input-container">
                        <label htmlFor="input2">{translation("Phone Number")}</label>
                        <input autoComplete="off" type="text" name="phoneNumber" value={inputsData.phoneNumber}
                               onChange={handleChange}/>
                    </div>

                    <div className="input-container">
                        <label htmlFor="input2">{translation("Email")}</label>
                        <input autoComplete="off" type="text" name="email" value={inputsData.email}
                               onChange={handleChange}/>
                    </div>

                    <div className="input-container">
                        <label htmlFor="input2">{translation("Role")}</label>
                        <select name="role" value={role} onChange={handleRoleChange} disabled>
                            <option value="Admin">Admin</option>
                            <option value="FuelSupervisor">Fuel Supervisor</option>
                        </select>
                    </div>

                    {
                        role !== "Admin" &&
                        <>
                            <div className="input-container">
                                <label htmlFor="dropdown">{translation("Select Station Groups")}</label>
                                <select id="dropdown" value={selectedGroupUsers} onChange={handleSelectStationGroup}>
                                    {
                                        stationGroupsData?.list?.map((option) => (
                                            <option key={option.id} value={option.id}>{option.name}</option>
                                        ))}
                                </select>
                            </div>
                            <div className="input-container">
                                {
                                    popupContent?.stations?.length ?
                                        <div className="input-container">
                                            <label htmlFor="select-stations">{translation("Select Station")}</label>
                                            <input
                                                type="text"
                                                value={searchStation}
                                                id="select-stations"
                                                style={{marginBottom: '15px'}}
                                                onChange={(e) => setSearchStation(e.target.value)}
                                            />

                                            <div style={{display: 'flex', flexWrap: 'wrap', gap: '10px'}}>
                                                {
                                                    filteredStations?.map((option) => (
                                                        <label htmlFor={option.id} key={option.id} style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            marginBottom: 0,
                                                            gap: '5px'
                                                        }}>
                                                            <input
                                                                type="checkbox"
                                                                checked={userChecked.includes(option.id)}
                                                                id={option.id}
                                                                onChange={() => handleChangeUserStation(option.id)}
                                                                style={{width: 'fit-content', marginTop: 0}}/>

                                                            <p style={{margin: 0}}>{option.name}</p>
                                                        </label>
                                                    ))
                                                }
                                            </div>
                                        </div> : ""
                                }
                            </div>
                        </>
                    }

                    <div className="buttons-container">
                        <button className="custom-popup-submit-button" type="submit"
                                onClick={handleSubmit}>{translation("Save")}</button>
                        <button className="custom-popup-cancel-button" type="button"
                                onClick={closeModal}>{translation("Cancel")}</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditUser;