import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {dispatch, handleSort, translation} from "../../../helpers";
import {getStationGroups, getStations} from "../../../helpers/apis";
import {
    globalSelector,
    setLoader,
    setObjFilter,
    setStationGroups,
    setStations,
    setToStations
} from "../../../redux/slices/globalSlice";

const StationGroupsContent = ({openPopup}) => {
    const {loggedInPerson, stationGroupsData, stationsData, token, toStations} = useSelector(globalSelector);
    const [expandedItemIds, setExpandedItemIds] = useState([]);

    useEffect(() => {
        if (!toStations.active) {
            dispatch(setLoader(true));
            dispatch(setObjFilter({}));
            getStationGroups(token)
                .then(({data}) => dispatch(setStationGroups(data)))
                .finally(() => dispatch(setLoader(false)));
        }
        if (toStations.active && toStations.product.name) {
            dispatch(setLoader(true));
            dispatch(setObjFilter({}));
            getStations({StationGroupId: toStations.product.id}, token)
                .then(({data}) => dispatch(setStations(data)))
                .finally(() => dispatch(setLoader(false)));
        }
    }, [toStations]);

    if (toStations.active && toStations.product.name) {
        return (
            <div className="custom-list-container transactions">
                <div className="back-to-station-groups" onClick={() => dispatch(setToStations({}))}>
                    {translation("Back to station groups")}
                </div>
                <table>
                    <thead>
                    <tr>
                        <th colSpan={2} onClick={() => handleSort('id')}>
                            <p>
                                <i className="icon-next-to-id id-icon"/>
                                <span>ID</span>
                            </p>
                        </th>
                        <th colSpan={2} onClick={() => handleSort('YandexStationId')}
                            title={translation("Yandex ID")}>
                            <p>
                                <i className="icon-order id-icon"/>
                                {translation("Yandex ID")}
                            </p>
                        </th>
                        <th colSpan={2} onClick={() => handleSort('name')} title={translation("Station Name")}>
                            <p>
                                <i className="icon-order id-icon"/>
                                {translation("Station Name")}
                            </p>
                        </th>
                        <th colSpan={2} onClick={() => handleSort('address')} title={translation("Address")}>
                            <p>
                                <i className="icon-order id-icon"/>
                                {translation("Address")}
                            </p>
                        </th>
                        <th colSpan={2} onClick={() => handleSort('phoneNumber')}
                            title={translation("Phone Number")}>
                            <p>
                                <i className="icon-order id-icon"/>
                                {translation("Phone Number")}
                            </p>
                        </th>
                        <th colSpan={2} onClick={() => handleSort('tin')}
                            title={translation("Tin")}>
                            <p>
                                <i className="icon-order id-icon"/>
                                {translation("Tin")}
                            </p>
                        </th>
                        <th colSpan={2}
                            title={translation("Fuel Types")}>
                            <p>
                                {translation("Fuel Types")}
                            </p>
                        </th>
                        {loggedInPerson === "Admin" ? <th colSpan={2}><p>{translation("Actions")}</p></th> : ""}
                    </tr>
                    </thead>
                    <tbody>
                    {stationsData?.list?.map((product) => (
                        <tr key={product.id}>
                            <td colSpan={2} title={product.id}><p>{product.id}</p></td>
                            <td colSpan={2} title={product.yandexStationId}>
                                <p className="yandexid-to-copy"
                                   style={{wordBreak: expandedItemIds.includes(product.id) ? "break-all" : "unset"}}
                                   onClick={() => {
                                       setExpandedItemIds((prevExpandedItemIds) => {
                                           if (prevExpandedItemIds.includes(product.id)) {
                                               return prevExpandedItemIds.filter((id) => id !== product.id);
                                           } else {
                                               return [...prevExpandedItemIds, product.id];
                                           }
                                       });
                                       navigator.clipboard.writeText(product.yandexStationId);

                                   }}
                                   title={product.yandexStationId}
                                >
                                    {product.yandexStationId}
                                </p>
                            </td>
                            <td colSpan={2} title={product.name}><p>{product.name}</p></td>
                            <td colSpan={2} title={product.address}><p>{product.address}</p></td>
                            <td colSpan={2} title={product.phoneNumber}><p>{product.phoneNumber}</p></td>
                            <td colSpan={2} title={product.tin}><p>{product.tin}</p></td>
                            <td colSpan={2}>
                                <table>
                                    <tbody>
                                    {product.fuelTypes.map((fuelType, index) => (
                                        <tr key={index}>
                                            <td><p>{fuelType.name} ({fuelType.countType})</p></td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </td>
                            {
                                loggedInPerson === "Admin" ?
                                    <>
                                        <td colSpan={2}>
                                            <div onClick={() => openPopup("EditStations", product)}>
                                                <p>
                                                    <i className="icon-edit edit-icon"/>
                                                </p>
                                            </div>
                                        </td>
                                    </>
                                    : ""
                            }
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        )
    }

    return (
        <div className="custom-list-container">
            <table>
                <thead>
                <tr>
                    <th colSpan={2} onClick={() => handleSort('id')}>
                        <p>
                            <i className="icon-next-to-id id-icon"/>
                            <span>ID</span>
                        </p>
                    </th>
                    <th colSpan={2} onClick={() => handleSort('name')}
                        title={translation("Station Groups Name")}>
                        <p>
                            <i className="icon-order id-icon"/>
                            {translation("Station Groups Name")}
                        </p>
                    </th>
                    <th colSpan={2} onClick={() => handleSort('address')} title={translation("Address")}>
                        <p>
                            <i className="icon-order id-icon"/>
                            {translation("Address")}
                        </p>
                    </th>
                    <th colSpan={2} onClick={() => handleSort('phoneNumber')}
                        title={translation("Phone Number")}>
                        <p>
                            <i className="icon-order id-icon"/>
                            {translation("Phone Number")}
                        </p>
                    </th>
                    {loggedInPerson === "Admin" ? <th><p>{translation("Actions")}</p></th> : ""}
                </tr>
                </thead>
                <tbody>
                {stationGroupsData?.list?.map((product) => (
                    <tr key={product.id}>
                        <td colSpan={2} title={product.id}><p>{product.id}</p></td>
                        <td colSpan={2} className="station-groups-name" title={product.name}
                            onClick={() => dispatch(setToStations({active: true, product}))}>
                            <p>{product.name}</p>
                        </td>
                        <td colSpan={2} title={product.address}><p>{product.address}</p></td>
                        <td colSpan={2} title={product.phoneNumber}><p>{product.phoneNumber}</p></td>
                        {
                            loggedInPerson === "Admin" ?
                                <td colSpan={2}>
                                    <div onClick={() => openPopup("EditStationGroups", product)}>
                                        <p>
                                            <i className="icon-edit edit-icon"/>
                                        </p>
                                    </div>
                                    {/*<div onClick={() => openPopup("DeleteStationGroups", product)}><i*/}
                                    {/*    className="icon-delete delete-icon"/></div>*/}
                                </td>
                                : ""
                        }
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default StationGroupsContent;