import React from 'react';
import {translation} from "../../../../../helpers";
import {useSelector} from "react-redux";
import {globalSelector} from "../../../../../redux/slices/globalSlice";

const AddNewStations = ({handleSubmit, inputsData, handleChange, handleFuelChangeStations, fuelTypesData, selectedFuelsStations, closeModal}) => {
    const {formFrontValidation} = useSelector(globalSelector);

    return (
        <div className="custom-modal" style={{overflow: "auto", maxHeight: "85vh"}}>
            <div className="custom-modal-content">
                <h3 className="custom-modal-title">{translation("Add new station")}</h3>
                <form onSubmit={handleSubmit}>
                    <div className="input-container">
                        <label htmlFor="input1">{translation("Name")}</label>
                        <input autoComplete="off" type="text" name="name" value={inputsData.name}
                               onChange={handleChange}/>
                        {
                            formFrontValidation?.name &&
                            <p className="error-input-field">{formFrontValidation?.name}</p>
                        }
                    </div>

                    <div className="input-container">
                        <label htmlFor="input2">{translation("Yandex ID")}</label>
                        <input autoComplete="off" type="text" name="yandexStationId"
                               value={inputsData.yandexStationId}
                               onChange={handleChange}/>
                        {
                            formFrontValidation?.yandexStationId &&
                            <p className="error-input-field">{formFrontValidation?.yandexStationId}</p>
                        }
                    </div>

                    <div className="input-container">
                        <label htmlFor="input2">{translation("Address")}</label>
                        <input autoComplete="off" type="text" name="address" value={inputsData.address}
                               onChange={handleChange}/>
                    </div>
                    <div className="input-container">
                        <label htmlFor="input2">{translation("Tin")}</label>
                        <input autoComplete="off" type="text" name="tin" value={inputsData.tin}
                               onChange={handleChange}/>

                        {
                            formFrontValidation?.tin &&
                            <p className="error-input-field">{formFrontValidation?.tin}</p>
                        }
                    </div>
                    <div className="input-container">
                        <label htmlFor="input2">{translation("Phone Number")}</label>
                        <input autoComplete="off" type="text" name="phoneNumber" value={inputsData.phoneNumber}
                               onChange={handleChange}/>
                    </div>
                    <div className="input-container">
                        <label htmlFor="input2">{translation("Lat")}</label>
                        <input autoComplete="off" type="text" name="lat" value={inputsData.lat}
                               onChange={handleChange}/>
                    </div>
                    <div className="input-container">
                        <label htmlFor="input2">{translation("Long")}</label>
                        <input autoComplete="off" type="text" name="long" value={inputsData.long}
                               onChange={handleChange}/>
                    </div>

                    <div className="input-container">
                        <label htmlFor="dropdown">{translation("Select Fuel Types")}</label>
                        <select multiple onChange={handleFuelChangeStations}>
                            {fuelTypesData?.list?.map((fuel) => (
                                <option
                                    key={fuel.id}
                                    value={fuel.id}
                                >
                                    {fuel.name} ({fuel.countType})
                                </option>
                            ))}
                        </select>
                    </div>
                    {
                        formFrontValidation?.fuelTypes &&
                        <p className="error-input-field">{formFrontValidation?.fuelTypes}</p>
                    }

                    <div className="selected-tags-container">
                        {selectedFuelsStations.map((selectedFuel) => (
                            <div className="selected-tag" key={selectedFuel}>
                                {fuelTypesData?.list?.find((fuel) => fuel.id === selectedFuel)?.name} (
                                {fuelTypesData?.list?.find((fuel) => fuel.id === selectedFuel)?.countType})
                                <button
                                    className="close-button"
                                    onClick={() => handleFuelChangeStations(null, selectedFuel)}
                                >
                                    x
                                </button>
                            </div>
                        ))}
                    </div>

                    <div className="buttons-container">
                        <button className="custom-popup-submit-button" type="submit"
                                onClick={handleSubmit}>{translation("Save")}</button>
                        <button className="custom-popup-cancel-button" type="button"
                                onClick={closeModal}>{translation("Cancel")}</button>
                    </div>
                </form>
            </div>
        </div>

    );
};

export default AddNewStations;