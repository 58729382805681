import React from 'react';
import {useSelector} from "react-redux";
import {mergeAndRemoveDuplicates, translation} from "../../../../../helpers";
import {globalSelector} from "../../../../../redux/slices/globalSlice";

const FuelPrices = ({closeModal, openPopup, setFuelTypePrices}) => {
    const {stationsData} = useSelector(globalSelector);
    const mergedAndUniqueFuelTypesArray = mergeAndRemoveDuplicates(stationsData?.list);

    const handleInputChange = (fuelType, value) => {
        setFuelTypePrices((prevPrices) => ({
            ...prevPrices,
            [fuelType]: Number(value),
        }));
    };

    return (
        <div className="custom-modal-prices" style={{overflow: "auto", maxHeight: "85vh"}}>
            <h3 className="custom-modal-title">{translation("Change all fuel prices in the Station")}</h3>
            <form onSubmit={() => openPopup("ConfirmFuelPrices")}>
                {mergedAndUniqueFuelTypesArray
                    ?.map((item, i) => (
                        <div key={i} className="fuel-type-container">
                            <div className="text-container">
                                <label>{item.name}</label>
                            </div>
                            <div className="prices-input-container">
                                <input
                                    name={item.name}
                                    className="custom-input"
                                    onChange={(e) => handleInputChange(item.id, e.target.value)}
                                />
                            </div>
                        </div>
                    ))}

                <div className="buttons-container">
                    <button className="custom-popup-submit-button" type="submit"
                            onClick={() => openPopup("ConfirmFuelPrices")}>{translation("Save")}
                    </button>
                    <button className="custom-popup-cancel-button" type="button"
                            onClick={closeModal}>{translation("Cancel")}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default FuelPrices;