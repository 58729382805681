import React from 'react';
import {translation} from "../../../helpers";

const Users = ({searchValue, handleSearch, openPopup}) => {
    return (
        <div className="add-new">
            <div className="search-container">
                <form>
                    <input autoComplete="off" type="text" value={searchValue} onChange={handleSearch}/>
                    <button onClick={(e) => handleSearch(e, searchValue)}>{translation("Search")} <i className='icon-search add-new-icon'></i></button>
                </form>
            </div>
            {/*<button>*/}
            {/*    <span className="add-new-text">{translation("Արտահանել")}</span>*/}
            {/*    <i className='icon-analytics add-new-icon'></i>*/}
            {/*</button>*/}

            <button onClick={() => openPopup("AddNewUser")}>
                <span className="add-new-text">{translation("Add new user")}</span>
                <i className='icon-add add-new-icon'></i>
            </button>
        </div>
    );
};

export default Users;