import {createSlice} from '@reduxjs/toolkit/dist/index';

const initialState = {
    loading: false,
    error: "",
    token: "",
    loggedInPerson: "",
    selectedChoice: "Fuel Types",
    selectedFuelTypeOption: "",
    fuelTypesData: {},
    stationGroupsData: {},
    stationsData: {},
    toStations: {active: false, product: {}},
    dispensersData: {},
    usersData: {},
    ObjFilter: {},
    transactionsData: {},
    selectedTransactionType: "All",
    formFrontValidation: {},
    fuelPrices: {}
};

export const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        resetState: () => {
            return initialState;
        },
        setLoader: (state, action) => {
            state.loading = action.payload
        },
        setError: (state, action) => {
            state.error = action.payload
        },
        setToken: (state, action) => {
            state.token = action.payload
        },
        setLoggedInPerson: (state, action) => {
            state.loggedInPerson = action.payload
        },
        setSelectedChoiceR: (state, action) => {
            state.selectedChoice = action.payload
        },
        setFuelTypes: (state, action) => {
            state.fuelTypesData = action.payload
        },
        setStationGroups: (state, action) => {
            state.stationGroupsData = action.payload
        },
        setToStations: (state, action) => {
            state.toStations = action.payload
        },
        setStations: (state, action) => {
            state.stationsData = action.payload
        },
        setDispensers: (state, action) => {
            state.dispensersData = action.payload
        },
        setUsers: (state, action) => {
            state.usersData = action.payload
        },
        setObjFilter: (state, action) => {
            state.ObjFilter = action.payload
        },
        setTransactions: (state, action) => {
            state.transactionsData = action.payload
        },
        setSelectedTransactionType: (state, action) => {
            state.selectedTransactionType = action.payload
        },
        setSelectedFuelTypeOption: (state, action) => {
            state.selectedFuelTypeOption = action.payload
        },
        setFrontValidation: (state, action) => {
            state.formFrontValidation = action.payload
        },
    },
});

export const {
    setLoader,
    setError,
    setToken,
    setLoggedInPerson,
    setSelectedChoiceR,
    resetState,
    setFuelTypes,
    setStationGroups,
    setToStations,
    setStations,
    setDispensers,
    setUsers,
    setObjFilter,
    setTransactions,
    setSelectedFuelTypeOption,
    setFrontValidation,
    setSelectedTransactionType
} = globalSlice.actions;

export const globalSelector = ((state) => state.global);

export default globalSlice.reducer;