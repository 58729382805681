import React from 'react';
import {useSelector} from "react-redux";
import {globalSelector} from "../../redux/slices/globalSlice"
import "../../assets/styles/Loading.css"

const Loading = () => {
    const {loading} = useSelector(globalSelector);

    if (!loading){
        return null
    }

    return (
        <div className="preloader">
            <div className="loader"></div>
        </div>
    );
};

export default Loading;