import React from 'react';
import {translation} from "../../../../../helpers";
import {useSelector} from "react-redux";
import {globalSelector} from "../../../../../redux/slices/globalSlice";

const AddNewStationGroup = ({inputsData, handleChange, handleSubmit, closeModal}) => {
    const {formFrontValidation} = useSelector(globalSelector);

    return (
        <div className="custom-modal">
            <div className="custom-modal-content">
                <h3 className="custom-modal-title">{translation("Add new station groups")}</h3>
                <form onSubmit={handleSubmit}>
                    <div className="input-container">
                        <label htmlFor="input1">{translation("Name")}</label>
                        <input autoComplete="off" type="text" name="name" value={inputsData.name}
                               onChange={handleChange}/>
                        {
                            formFrontValidation?.name &&
                            <p className="error-input-field">{formFrontValidation?.name}</p>
                        }
                    </div>

                    <div className="input-container">
                        <label htmlFor="input2">{translation("Address")}</label>
                        <input autoComplete="off" type="text" name="address" value={inputsData.address}
                               onChange={handleChange}/>
                        {
                            formFrontValidation?.address &&
                            <p className="error-input-field">{formFrontValidation?.address}</p>
                        }
                    </div>

                    <div className="input-container">
                        <label htmlFor="input2">{translation("Phone Number")}</label>
                        <input autoComplete="off" type="text" name="phoneNumber" value={inputsData.phoneNumber}
                               onChange={handleChange}/>
                        {
                            formFrontValidation?.phoneNumber &&
                            <p className="error-input-field">{formFrontValidation?.phoneNumber}</p>
                        }
                    </div>


                    <div className="buttons-container">
                        <button className="custom-popup-submit-button" type="submit"
                                onClick={handleSubmit}>{translation("Save")}</button>
                        <button className="custom-popup-cancel-button" type="button"
                                onClick={closeModal}>{translation("Cancel")}</button>
                    </div>
                </form>
            </div>
        </div>

    );
};

export default AddNewStationGroup;