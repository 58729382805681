import React, {useEffect} from 'react';
import {useSelector} from "react-redux";
import {getFuelTypes} from "../../../helpers/apis";
import {translation, dispatch, handleSort} from "../../../helpers";
import {globalSelector, setFuelTypes, setLoader, setObjFilter} from "../../../redux/slices/globalSlice";

const FuelTypes = ({openPopup}) => {
    const {token, fuelTypesData} = useSelector(globalSelector);

    useEffect(() => {
        dispatch(setLoader(true));
        dispatch(setObjFilter({}));
        getFuelTypes(token, {
            pageIndex: '1',
        })
            .then(({data}) => dispatch(setFuelTypes(data)))
            .finally(() => dispatch(setLoader(false)))
    }, []);

    return (
        <div className="custom-list-container">
            <table>
                <thead>
                <tr>
                    <th colSpan={2} onClick={() => handleSort('Id')}>
                        <p>
                            <i className="icon-next-to-id id-icon"/>
                            <span>ID</span>
                        </p>
                    </th>
                    <th colSpan={2} onClick={() => handleSort('YandexFuelTypeId')}
                        title={translation('Yandex ID')}>
                        <p><i className="icon-order id-icon"></i>{translation('Yandex ID')}</p>
                    </th>
                    <th colSpan={2} onClick={() => handleSort('Name')} title={translation('Fuel Name')}>
                        <p><i className="icon-order id-icon"></i>{translation('Fuel Name')}</p>
                    </th>
                    <th colSpan={2} title={translation('Adg code')}>
                        <p>{translation('Adg code')}</p>
                    </th>
                    <th colSpan={2} title={translation('Department Id')}>
                        <p>{translation('Department Id')}</p>
                    </th>
                    <th colSpan={2} onClick={() => handleSort('CountType')} title={translation('Unit of measurement')}>
                        <p><i className="icon-order id-icon"></i>{translation('Unit of measurement')}</p>
                    </th>
                    <th colSpan={2}><p>{translation('Actions')}</p></th>
                </tr>
                </thead>

                <tbody>
                {fuelTypesData?.list?.map((product) => (
                    <tr key={product.id}>
                        <td colSpan={2}><p>{product.id}</p></td>
                        <td colSpan={2}title={product.yandexFuelTypeId}><p>{product.yandexFuelTypeId}</p></td>
                        <td colSpan={2} title={product.name}><p>{product.name}</p></td>
                        <td colSpan={2} title={product.adgCode}><p>{product.adgCode}</p></td>
                        <td colSpan={2} title={product.departmentId}><p>{product.departmentId}</p></td>
                        <td colSpan={2}><p>{product.countType}</p></td>
                        <td colSpan={2}>
                            <div onClick={() => openPopup("EditFuelType", product)}>
                                <p><i className="icon-edit edit-icon"/></p>
                            </div>
                            {/*<div onClick={() => openPopup("DeleteFuelType", product)}><i className="icon-delete delete-icon"/></div>*/}
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default FuelTypes;