import React, {useEffect} from 'react';
import {useSelector} from "react-redux";
import {getStationGroups, getStations, getUsers} from "../../../helpers/apis";
import {dispatch, handleSort, translation} from "../../../helpers";
import {globalSelector, setLoader, setStationGroups, setStations, setUsers} from "../../../redux/slices/globalSlice";

const UsersContent = ({openPopup}) => {
    const {usersData, token} = useSelector(globalSelector);

    useEffect(() => {
        dispatch(setLoader(true));
        getUsers({}, token)
            .then(({data}) => dispatch(setUsers(data)))
            .then(()=>  getStationGroups(token, {pageSize: '300'})
                .then(({data}) => {
                    dispatch(setStationGroups(data));
                    getStations({StationGroupId: Number(data?.list[0]?.id)}, token, {pageSize: '300'})
                        .then(({data}) => {
                            dispatch(setStations(data));
                        })
                })
            .finally(() => dispatch(setLoader(false))))
    }, []);

    return (
        <div className="custom-list-container">
            <table>
                <thead>
                <tr>
                    <th colSpan={2} onClick={() => handleSort('id')}>
                        <p>
                            <i className="icon-next-to-id id-icon"/>
                            <span>ID</span>
                        </p>
                    </th>
                    <th colSpan={2} onClick={() => handleSort('FullName')} title={translation("Name Surename")}>
                        <p>
                            <i className="icon-order id-icon"/>
                            {translation("Name Surename")}
                        </p>
                    </th>
                    <th colSpan={2} onClick={() => handleSort('Role')} title={translation("Role")}>
                        <p>
                            <i className="icon-order id-icon"/>
                            {translation("Role")}
                        </p>
                    </th>
                    <th colSpan={2} onClick={() => handleSort('StationGroupName')} title={translation("Stations")}>
                        <p>
                            <i className="icon-order id-icon"/>
                            {translation("Stations")}
                        </p>
                    </th>
                    <th colSpan={2} onClick={() => handleSort('PhoneNumber')} title={translation("Tel")}>
                        <p>
                            <i className="icon-order id-icon"/>
                            {translation("Tel")}
                        </p>
                    </th>
                    <th colSpan={2}>
                        <p>
                            {translation("Actions")}
                        </p>
                    </th>
                </tr>
                </thead>

                <tbody>
                {usersData?.list?.map((product) => (
                    <tr key={product.id}>
                        <td colSpan={2}><p>{product.id}</p></td>
                        <td colSpan={2}><p>{product.firstName} {product.lastName}</p></td>
                        <td colSpan={2}><p>{product.role}</p></td>
                        <td colSpan={2}><p>{product.stations.map(station => station.name).join(', ')}</p></td>
                        <td colSpan={2}><p>{product.phoneNumber}</p></td>
                        <td colSpan={2} className="edit-delete-icons">
                            <div onClick={() => openPopup("EditUser", product)}>
                                <p>
                                    <i className="icon-edit edit-icon"/>
                                </p>
                            </div>
                            <div onClick={() => openPopup("DeleteUser", product)}>
                                <p>
                                    <i className="icon-delete delete-icon"/>
                                </p>
                            </div>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default UsersContent;