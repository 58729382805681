import React, {useEffect} from 'react';
import {dispatch, translation} from "../../../../../helpers";
import {getStations} from "../../../../../helpers/apis";
import {globalSelector, setLoader, setStations} from "../../../../../redux/slices/globalSlice";
import {useSelector} from "react-redux";

const EditDispenser = ({
     handleSubmit,
     inputsData,
     popupContent,
     handleChange,
     selectedGroupDispensers,
     handleSelectStationGroup,
     stationGroupsData,
     stationsData,
     selectedStationDispensers,
     handleSelectStation,
     selectedStationByID,
     handleFuelChangeDispensers,
     setSelectedStationGroupDispensers,
     setSelectedStationDispensers,
     selectedFuelsDispensers,
     closeModal
}) => {
    const {token} = useSelector(globalSelector);

    useEffect(()=>{
        getStations({StationGroupId: Number(popupContent?.station?.stationGroup?.id)}, token,{pageSize: "300"})
            .then(({data}) => {
                dispatch(setStations(data));
                    setSelectedStationGroupDispensers(Number(popupContent?.station?.stationGroup?.id));
                    setSelectedStationDispensers(popupContent?.station?.id);
            })
            .finally(() => dispatch(setLoader(false)));
    },[popupContent]);

    return (
        <div className="custom-modal" style={{overflow: "auto", maxHeight: "85vh"}}>
            <div className="custom-modal-content">
                <h3 className="custom-modal-title">{translation("Edit Dispenser")}</h3>
                <form onSubmit={handleSubmit}>
                    <div className="input-container">
                        <label htmlFor="input1">{translation("Serial Number")}</label>
                        <input autoComplete="off" type="text" name="serialNumber"
                               value={inputsData.serialNumber}
                               placeholder={popupContent.serialNumber}
                               onChange={handleChange}/>
                    </div>

                    <div className="input-container">
                        <label htmlFor="input2">{translation("Dispenser ID")}</label>
                        <input autoComplete="off" type="text" name="yandexDispenserId"
                               value={inputsData.yandexDispenserId}
                               placeholder={popupContent.yandexDispenserId}
                               onChange={handleChange}/>
                    </div>

                    <div className="input-container">
                        <label htmlFor="dropdown">{translation("Select Station Groups")}</label>
                        <select id="dropdown"
                                value={selectedGroupDispensers}
                                onChange={handleSelectStationGroup}>
                            {
                                stationGroupsData?.list?.map((option) => (
                                    <option key={option.id} value={option.id}>{option.name}</option>
                                ))}
                        </select>
                    </div>

                    {
                        stationsData?.list?.length ?
                            <div className="input-container">
                                <label htmlFor="dropdown">{translation("Select Station")}</label>
                                <select id="dropdown" value={selectedStationDispensers}
                                        onChange={handleSelectStation}>
                                    {
                                        stationsData?.list?.map((option) => (
                                            <option key={option.id} value={option.id}>{option.name}</option>
                                        ))}
                                </select>
                            </div> : ""
                    }

                    {
                        selectedStationByID &&
                        <>
                            <div className="input-container">
                                <label htmlFor="dropdown">{translation("Select Fuel Types")}</label>
                                <select multiple onChange={handleFuelChangeDispensers}>
                                    {selectedStationByID?.fuelTypes?.map((fuel) => (
                                        <option
                                            key={fuel.id}
                                            value={fuel.id}>
                                            {fuel.name} ({fuel.countType})
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="selected-tags-container">
                                {selectedFuelsDispensers?.map((selectedFuel) => (
                                    <div className="selected-tag" key={selectedFuel}>
                                        {selectedStationByID?.fuelTypes?.find((fuel) => fuel.id === selectedFuel)?.name} (
                                        {selectedStationByID?.fuelTypes?.find((fuel) => fuel.id === selectedFuel)?.countType})
                                        <button
                                            className="close-button"
                                            onClick={() => handleFuelChangeDispensers(null, selectedFuel)}
                                        >
                                            x
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </>
                    }

                    <div className="buttons-container">
                        <button className="custom-popup-submit-button" type="submit"
                                onClick={handleSubmit}>{translation("Save")}</button>
                        <button className="custom-popup-cancel-button" type="button"
                                onClick={closeModal}>{translation("Cancel")}</button>
                    </div>
                </form>
            </div>
        </div>

    );
};

export default EditDispenser;