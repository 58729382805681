import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux';
import {persistor, store} from './redux/store';
import App from './App';
import i18next from "i18next";
import {I18nextProvider} from "react-i18next";
import { PersistGate } from 'redux-persist/integration/react';
import reportWebVitals from './reportWebVitals';
import ErrorBoundary from "./ErrorBoundary";

import './index.css';
import './assets/styles/Login.css';
import './assets/styles/Dashboard.css';
import './assets/styles/Addnew.css';
import './assets/styles/Error.css';
import "./assets/styles/Pagination.css"
import "./assets/icons/style.css"
import "./assets/styles/Sidebar.css"
import "./assets/styles/Header.css"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <I18nextProvider i18n={i18next}>
            <ErrorBoundary>
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                    <App/>
                    </PersistGate>
                </Provider>
            </ErrorBoundary>
        </I18nextProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
