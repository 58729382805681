import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import globalReducer from './slices/globalSlice';
import {encryptTransform} from "redux-persist-transform-encrypt";

const encryptor = encryptTransform({
    secretKey: 'yandex-front-fueling-super-secret2121',
});

const persistConfig = {
    key: 'root',
    storage: storage,
    transforms: [encryptor]
};

const rootReducer = combineReducers({
    global: globalReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: [
        ...getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: ['persist/PERSIST'],
            },
        }),
    ],
    devTools: false,
});

export const persistor = persistStore(store);
