import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from "react-redux";
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom';
import {isError, dispatch} from "./helpers";
import {removeLngPrefix} from './helpers/index';
import AuthRedirect from "./HOC/AuthRedirect";
import {globalSelector, setLoader} from "./redux/slices/globalSlice";
import Login from './components/login/Login';
import Dashboard from './components/dashboard/Dashboard';
import Loading from "./components/loading/Loading";
import Error from "./components/error/Error";
import ForgotPassword from "./components/login/ForgotPassword";

function App() {
    const {i18n, i18n: {language}} = useTranslation();
    const {token} = useSelector(globalSelector);

    useEffect(() => {
        const currentPathname = window.location.href.replace(
            window.location.origin,
            ''
        );

        document.documentElement.lang = language;

        const newPathname = `/${language}${removeLngPrefix(currentPathname)}`;

        if (currentPathname !== newPathname) {
            window.history.replaceState({}, '', newPathname);
        }
    }, [language, i18n]);

    useEffect(()=>{
        dispatch(setLoader(false));
    },[]);

    return (
        <Router basename={`/${language}/`}>
            <Loading/>
            {isError() && <Error/>}
            <Switch>
                <AuthRedirect token={token}>
                    <Route exact path="/">
                        <Redirect to="/login"/>
                    </Route>
                    <Route path="/login" component={Login}/>
                    <Route path="/forgot-password" component={ForgotPassword}/>
                    <Route path="/dashboard" component={Dashboard}/>
                    <Redirect to="/login"/>
                </AuthRedirect>
            </Switch>
        </Router>
    );
}

export default App;