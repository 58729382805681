import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom"
import {useSelector} from "react-redux";
import Header from "./header/Header";
import Sidebar from "./sidebar/SideBar";
import Content from "./content/Content";
import CustomPopup from "./content/CustomPopUp";
import {globalSelector, setFrontValidation} from "../../redux/slices/globalSlice";
import Pagination from "./pagination/Pagination";
import {currentTimeInArmeniaEnd, currentTimeInArmeniaStart, dispatch, searchArray} from "../../helpers";
import AddNew from "./addNew/AddNew";

const Dashboard = () => {
    const history = useHistory();
    const {selectedChoice} = useSelector(globalSelector);
    const [selected, setSelectedChoice] = useState(selectedChoice);
    const [localStorageCleared, setLocalStorageCleared] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [dateStart, setDateStart] = useState(() => {
        const currentDate = new Date();
        currentDate.setHours(0, 1, 0, 0);
        return currentDate;
    });
    const [dateEnd, setDateEnd] = useState(new Date());
    const [popupContent, setPopupContent] = useState({});
    const [selectedPopup, setSelectedPopup] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = searchArray().rowsPerPage;
    const data = searchArray().count;

    const openPopup = (popupType, content) => {
        setSelectedPopup(popupType);
        setPopupContent(content);
        setIsPopupOpen(true);
    };

    const closePopup = () => {
        setIsPopupOpen(false);
        dispatch(setFrontValidation({}));
        setSelectedPopup(null);
    };

    useEffect(() => {
        setCurrentPage(1);
        setDateStart(() => {
            const currentDate = new Date();
            currentDate.setHours(0, 1, 0, 0);
            return currentDate;
        });
        setDateEnd(new Date())
    }, [selectedChoice]);

    useEffect(() => {
        const handleStorageChange = () => {
            if (localStorage.length === 0) {
                setLocalStorageCleared(true);
                history.push('/login');
            }
        };

        window.addEventListener('storage', handleStorageChange);

        if (typeof Storage !== 'undefined') {
            if (!localStorage.getItem('persist:root')) {
                setLocalStorageCleared(true);
                history.push('/login');
            }
        } else {
            console.log('Local storage is not supported by the browser');
        }

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, [localStorageCleared]);

    useEffect(() => {
        document.body.style.overflow = isPopupOpen ? "hidden" : "auto";

        return () => {
            document.body.style.overflow = "auto";
        };
    }, [isPopupOpen]);

    return (
        <div className="dashboard-app">
            <div className="container-fluent">
                <Header/>
                <div className="content">
                    <Sidebar
                        selectedChoice={selected}
                        setSelectedChoice={setSelectedChoice}
                    />
                    <div className="dashboard-container">
                        <AddNew
                            dateEnd={dateEnd}
                            openPopup={openPopup}
                            dateStart={dateStart}
                            setDateEnd={setDateEnd}
                            setDateStart={setDateStart}

                        />
                        <div className='container-fluent'>

                            <Content
                                openPopup={openPopup}
                                dateEnd={currentTimeInArmeniaEnd(dateEnd)}
                                dateStart={currentTimeInArmeniaStart(dateStart)}
                                setSelectedChoice={setSelectedChoice}
                            />
                        </div>
                        {
                            selectedChoice && <Pagination
                                totalItems={data}
                                itemsPerPage={itemsPerPage}
                                dateEnd={dateEnd}
                                dateStart={dateStart}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                            />
                        }
                    </div>

                </div>
                {isPopupOpen &&
                <CustomPopup
                    popupType={selectedPopup}
                    openPopup={openPopup}
                    closeModal={closePopup}
                    popupContent={popupContent}
                />}
            </div>
        </div>
    );
};

export default Dashboard;