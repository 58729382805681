import React from 'react';
import {exportAsCSV, translation} from "../../../helpers";

const FuelTypes = ({openPopup}) => {
    return (
        <div className="add-new">
            <button onClick={exportAsCSV}>
                <span className="add-new-text">{translation("Export")}</span>
                <i className='icon-analytics add-new-icon'></i>
            </button>
            <button onClick={() => {
                openPopup("AddNewFuelType")
            }}>
                <span className="add-new-text">{translation("Add new fuel")}</span>
                <i className='icon-add add-new-icon'></i>
            </button>
        </div>
    );
};

export default FuelTypes;