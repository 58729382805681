import React from 'react';
import {dispatch, translation} from '../../../helpers';
import {useSelector} from "react-redux";
import {globalSelector, setSelectedChoiceR} from "../../../redux/slices/globalSlice";

const choicesAdminData = [
    {key: 'type', label: 'Fuel Types'},
    {key: 'station groups', label: 'Station Groups'},
    {key: 'fuel prices', label: 'Fuel Prices'},
    {key: 'dispensers', label: 'Dispensers'},
    {key: 'users', label: 'Users'},
    {key: 'transactions', label: 'Transactions'},
];

const choicesFuelData = [
    {key: 'station groups', label: 'Station Groups'},
    {key: 'fuel prices', label: 'Fuel Prices'},
    {key: 'transactions', label: 'Transactions'},
];

const Sidebar = ({selectedChoice, setSelectedChoice}) => {
    const {loggedInPerson} = useSelector(globalSelector);

    const selectChoice = (clicked) => {
        setSelectedChoice(clicked);
        dispatch(setSelectedChoiceR(clicked));
    };

    return (
        <div className="sidebar">
            <i className='icon-car car-icon'></i>
            <div className="choices">
                {
                    loggedInPerson === "Admin" ? choicesAdminData.map((choice) => (
                            <div
                                key={choice.key}
                                className={selectedChoice === choice.label ? 'choice selected' : 'choice'}
                                onClick={() => selectChoice(choice.label)}
                            >
                                {translation(choice.label)}
                            </div>
                        ))
                        :
                        choicesFuelData.map((choice) => (
                            <div
                                key={choice.key}
                                className={selectedChoice === choice.label ? 'choice selected' : 'choice'}
                                onClick={() => selectChoice(choice.label)}
                            >
                                {translation(choice.label)}
                            </div>
                        ))
                }
            </div>
        </div>
    );
};

export default Sidebar;